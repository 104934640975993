import React, {useEffect, useContext, useState} from 'react';
import {uploadFile} from "../../consumers/backendConsumer";
import {clearInputValue, extensionYtamañoDeArchivoEsValido, jsonEstaVacio, jsonNoEstaVacio, obtenerArchivoDeEvent, obtenerElementPorId, obtenerPaisPeru, obtenerValorDeElementPorId, responseUploadFileSuccess} from "../../helper/utils";
import {toast} from "react-toastify";
import {BANCOS, CIUDAD_ID_FICHA_CAMPO, DEPARTAMENTO_ID_FICHA_CAMPO, EXTENSIONES_VALIDAS_DE_ARCHIVOS, IDFIELDS, MENUS_FICHA_DATOS, PAIS_ID_FICHA_CAMPO} from "../../constants/hardCodedConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {showToastErrorMaxFileSize} from "../../components/alerts/AlertErrorMaxFileSize";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {enviarSolicitudAPI, getColaborador, getColaboradorById, getDropDownColaboradorAPI, getFichaCampoPorSeccionAPI, getPaisesAPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import AsyncSelectInput from "../../components/Select/AsyncSelectInput";
import AsyncSelectInputGET from "../../components/Select/AsyncSelectInputGET";

const ModalSolicitarCambios = ({setShowModalSolicitarCambios, showUpdateConfirmationModal, idSeccionShowModalSolicitarCambios, setIdSeccionShowModalSolicitarCambios, reloadViewPageSeccion}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [fichaCamposDropdown, setFichaCamposDropdown] = useState([]);
    const [fichaCampos, setFichaCampos] = useState([]);
    const [idFichaCampoSeleccionado, setFichaCampoSeleccionado] = useState();

    const [hideInputAdjuntaDNI, setHideInputAdjuntaDNI] = useState(true);
    const [hideInputActaDeMatrimonio, setHideInputActaDeMatrimonio] = useState(true);
    const [hideInputActaDeNacimiento, setHideInputActaDeNacimiento] = useState(true);
    const [hideInputConstanciaCambioCuenta, setHideInputConstanciaCambioCuenta] = useState(true);

    const [filas, setFilas] = useState([]);

    const [s3UploadFileResponseDNI, setS3UploadFileResponseDNI] = useState({});
    const [s3UploadFileResponseActaDeMatrimonio, setS3UploadFileResponseActaDeMatrimonio] = useState({});
    const [s3UploadFileResponseActaDeNacimiento, setS3UploadFileResponseActaDeNacimiento] = useState({});
    const [s3UploadFileResponseConstanciaCambioCuenta, setS3UploadFileResponseConstanciaCambioCuenta] = useState({});

    const [fileNameDNI, setFileNameDNI] = useState("");
    const [fileNameActaDeMatrimonio, setFileNameActaDeMatrimonio] = useState("");
    const [fileNameActaDeNacimiento, setFileNameActaDeNacimiento] = useState("");
    const [fileNameConstanciaCambioCuenta, setFileNameConstanciaCambioCuenta] = useState("");

    const firstGeneralJsonOptionSelect = {"Value": 0, "Text": "Seleccionar"};

    const [departamentos, setDepartamentos] = useState([firstGeneralJsonOptionSelect]);
    const [provincias, setProvincias] = useState([firstGeneralJsonOptionSelect]);
    const [distritos, setDistritos] = useState([firstGeneralJsonOptionSelect]);


    const [unidadesSeccionDatosEmpresa, setUnidadesSeccionDatosEmpresa] = useState([firstGeneralJsonOptionSelect]);
    const [departamentosSeccionDatosEmpresa, setDepartamentosSeccionDatosEmpresa] = useState([firstGeneralJsonOptionSelect]);
    const [areasDatosSeccionDatosEmpresa, setAresSeccionDatosEmpresa] = useState([firstGeneralJsonOptionSelect]);
    const [seccionesSeccionDatosEmpresa, setSeccionesSeccionDatosEmpresa] = useState([firstGeneralJsonOptionSelect]);

    const [currentYear] = useState(new Date().getFullYear());
    const firstJsonOptionSelectFichaCampo = {"IdFichaCampo": 0, "NombreCampo": "Seleccionar Campo"};
    const [idSeccionnnn, setIdSeccionnnn] = useState();

    const [subiendoArchivoDNI, setSubiendoArchivoDNI] = useState(false);
    const [subiendoArchivoActaDeMatrimonio, setSubiendoArchivoActaDeMatrimonio] = useState(false);
    const [subiendoArchivoActaDeNacimiento, setSubiendoArchivoActaDeNacimiento] = useState(false);
    const [subiendoArchivoConstanciaCambioCuenta, setSubiendoArchivoConstanciaCambioCuenta] = useState(false);

    const htmlElementIdInputFileDNI = "solicitud-cambios-modal-input-file-DNI";
    const htmlElementIdInputFileActaDeMatrimonio = "solicitud-cambios-modal-input-file-ActaDeMatrimonio";
    const htmlElementIdInputFileActaNacimiento = "solicitud-cambios-modal-input-file-acta-nacimiento";
    const htmlElementIdInputFileConstanciaCambioCuenta = "solicitud-cambios-modal-input-file-ConstanciaCambioCuenta";

    const [executePostRequest] = usePostRequest();
    const [executeGetRequest] = useGetRequest();

    const [liderSeleccionado, setLiderSeleccionado] = useState({});

    useDidMountEffect(() => {
        console.debug("idSeccionShowModalSolicitarCambios ", idSeccionShowModalSolicitarCambios);
        init();
    }, [idSeccionnnn]);

    useDidMountEffect(() => {
        if (idSeccionShowModalSolicitarCambios) {
            setIdSeccionnnn(idSeccionShowModalSolicitarCambios);
        }
    }, [idSeccionShowModalSolicitarCambios]);

    const init = async () => {
        if (idSeccionnnn) {
            let response = await executeGetRequest(getFichaCampoPorSeccionAPI(idSeccionnnn));
            if (responseCode200(response)) {
                setFichaCamposDropdown([firstJsonOptionSelectFichaCampo, ...response.data.FichaCampos]);
                setFichaCampos(response.data.FichaCampos);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }

            setShowModalSolicitarCambios(true);
        }
    };


    const closeSolicitarCambiosModal = () => {
        console.debug("closeSolicitarCambiosModal");
        setIdSeccionnnn(null);
        setIdSeccionShowModalSolicitarCambios(null);

        setShowModalSolicitarCambios(false);
        setFilas([]);

        setHideInputAdjuntaDNI(true);
        resetUploadDNI();

        setHideInputActaDeMatrimonio(true);
        resetUploadActaDeMatrimonio();

        setHideInputActaDeNacimiento(true);
        resetUploadActaDeNacimiento();

        setHideInputConstanciaCambioCuenta(true);
        resetUploadConstanciaCambioCuenta();

        setFichaCampoSeleccionado(0);

        resetForm();
    };

    async function manageApiSinParametro(elementoAAgregar, jsonAañadir) {
        let response = await executeGetRequest(elementoAAgregar.UrlSolicitudDatos);
        console.debug("elementoAAgregar.UrlSolicitudDatos ", elementoAAgregar.UrlSolicitudDatos);
        console.debug("response ", response);
        if (responseCode200(response)) {
            console.debug("response.data ", response.data);
            let items = response.data;
            console.log("NO!!!!", items , elementoAAgregar)
            if(elementoAAgregar["IdFichaCampo"] == IDFIELDS.IDFIELDBANCOSUELDO) {
                items = items.filter( item =>item.Value !== BANCOS.NOAPLICA)
                console.log("NO!!!!", items)
            }
            
            jsonAañadir = {...jsonAañadir, "dropDownList": [firstGeneralJsonOptionSelect, ...items]};


            if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetUbigeoDepartamentos") {
                setDepartamentos([firstGeneralJsonOptionSelect, ...response.data]);
            }

            if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetUnidades") {
                setUnidadesSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
            }
        }
        return jsonAañadir;
    }

    async function manageApiConParametro(elementoAAgregar, jsonAañadir) {
        console.debug("manageApiConParametro, elementoAAgregar: ", {elementoAAgregar, jsonAañadir});
        let papa = obtenerPapa(elementoAAgregar.IdFichaCampo);
        let regex = new RegExp('^[0-9]+$');

        if (regex.test(papa.DatoActualValue)) {
            let api = elementoAAgregar.UrlSolicitudDatos + papa.DatoActualValue;
            let response = await executeGetRequest(api);

            if (responseCode200(response)) {
                jsonAañadir = {...jsonAañadir, "dropDownList": [firstGeneralJsonOptionSelect, ...response.data]};

                if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetUbigeoProvinciaByIdDepartamento/") {
                    setProvincias([firstGeneralJsonOptionSelect, ...response.data]);
                } else if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetUbigeoDistritoByIdProvincia/") {
                    setDistritos([firstGeneralJsonOptionSelect, ...response.data]);
                } else if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetDepartamentosbyUnidad/") {
                    console.debug("Datos/GetDepartamentosbyUnidad/");
                    console.debug("response ", response.data);
                    setDepartamentosSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
                } else if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetAreasByDepartamento/") {
                    setAresSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
                } else if (elementoAAgregar.UrlSolicitudDatos == "Datos/GetSeccionesByArea/") {
                    setSeccionesSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
                }
            }
        } else {
            console.warn(`valor de DatoActualValue de papá no es número, papa.DatoActualValue: '${papa.DatoActualValue}' , papa.NombreCampo: '${papa.NombreCampo}' `);
        }
        return jsonAañadir;
    }

    const armarJsonFila = async (elementoAAgregar) => {
        let jsonAañadir = elementoAAgregar;
        if (elementoAAgregar && elementoAAgregar.TipoDato == "List" && elementoAAgregar.UrlSolicitudDatos) {
            if (elementoAAgregar.UrlSolicitudDatos[elementoAAgregar.UrlSolicitudDatos.length - 1] == "/") {
                jsonAañadir = await manageApiConParametro(elementoAAgregar, jsonAañadir);
            } else {
                jsonAañadir = await manageApiSinParametro(elementoAAgregar, jsonAañadir);
            }
        }

        if (elementoAAgregar && elementoAAgregar.TipoDato == "MultiList" && elementoAAgregar.UrlSolicitudDatos) {
            if ((elementoAAgregar.UrlSolicitudDatos[elementoAAgregar.UrlSolicitudDatos.length - 1] != "/")) {
                jsonAañadir = await manageApiSinParametro(elementoAAgregar, jsonAañadir);
            }
        }
        return jsonAañadir;
    };

    const obtenerFilaJsonAAgregarEnCasoYaEsteAgregadoRetornaJsonVacio = async (idCampo) => {
        let fijaJson = {};
        if (idCampo != undefined && idCampo != 0) {
            let yaEstaAgregado = filas.filter(campo => campo.IdFichaCampo == idCampo);
            if (yaEstaAgregado.length == 0) {
                let elementoAagregarArray = fichaCampos.filter(campo => campo.IdFichaCampo == idCampo);
                if (elementoAagregarArray && elementoAagregarArray.length > 0) {
                    fijaJson = await armarJsonFila(elementoAagregarArray[0]);
                }
            }
        }
        return fijaJson;
    };

    const hayNuevaDependencia = (IdDependencia) => {
        return IdDependencia != null;
    };

    async function agregarFilaATempYSustentoEnCasoNoEsteAgregado(tempFilas, idCampo) {
        let filasInmutable = tempFilas;

        let filaJsonAagregar = await obtenerFilaJsonAAgregarEnCasoYaEsteAgregadoRetornaJsonVacio(idCampo);
        if (jsonNoEstaVacio(filaJsonAagregar)) {
            tempFilas = [...tempFilas, filaJsonAagregar];
        }
        if (jsonNoEstaVacio(filaJsonAagregar) && filaJsonAagregar.Sustento) {
            let filaa = filasInmutable.filter(fila => fila.Sustento == filaJsonAagregar.Sustento);
            if (filaa.length === 0) {

                if (filaJsonAagregar.Sustento == "ACTA_MATRIMONIO") {
                    setHideInputActaDeMatrimonio(false);
                }
                if (filaJsonAagregar.Sustento == "ACTA_NACIMIENTO") {
                    setHideInputActaDeNacimiento(false);
                }
                if (filaJsonAagregar.Sustento == "DNI") {
                    setHideInputAdjuntaDNI(false);
                }
                if (filaJsonAagregar.Sustento == "CONSTANCIA_CAMBIO_CUENTA") {
                    setHideInputConstanciaCambioCuenta(false);
                }
            }
        }
        return {filaJson: filaJsonAagregar, newfilas: tempFilas};
    }

    const addField = async (e, idFichaCampoSeleccionadoo, filass) => {
        if (e) {
            e.preventDefault();
        }

        let tempFilas = filass;

        console.debug(`idFichaCampoSeleccionado a agregar es: ${idFichaCampoSeleccionadoo}`);

        const temp = await agregarFilaATempYSustentoEnCasoNoEsteAgregado(tempFilas, idFichaCampoSeleccionadoo);
        let filaJson = temp.filaJson;
        tempFilas = temp.newfilas;

        let filaAgregada = filaJson;


        do {
            if (jsonNoEstaVacio(filaAgregada) && hayNuevaDependencia(filaAgregada.IdDependencia)) {
                const temp2 = await agregarFilaATempYSustentoEnCasoNoEsteAgregado(tempFilas, filaAgregada.IdDependencia);
                filaAgregada = temp2.filaJson;
                tempFilas = temp2.newfilas;
            }
        } while (hayNuevaDependencia(filaAgregada.IdDependencia));

        if (tempFilas.length > 0) {
            setFilas(tempFilas);
        }
    };

    const handleFichaCampoChange = (e) => {
        setFichaCampoSeleccionado(e.target.value);
    };

    const eliminarFilaYElminarSustentoEnCasoLasOtrasFilasNoLoNecesiten = (newFilas, idCampoAEliminar) => {
        let campoAEliminar = newFilas.filter(campo => campo.IdFichaCampo == idCampoAEliminar)[0];
        newFilas = newFilas.filter(campo => campo.IdFichaCampo != idCampoAEliminar);

        return {filaJson: campoAEliminar, newFilas};
    };

    function eliminarTodasLasDependenciasHijo(newfilas, idCampoAEliminar) {
        const temp = eliminarFilaYElminarSustentoEnCasoLasOtrasFilasNoLoNecesiten(newfilas, idCampoAEliminar);
        let filaJson = temp.filaJson;
        newfilas = temp.newFilas;

        let filaEliminada = filaJson;

        do {
            if (filaEliminada && hayNuevaDependencia(filaEliminada.IdDependencia)) {
                let temp2 = eliminarFilaYElminarSustentoEnCasoLasOtrasFilasNoLoNecesiten(newfilas, filaEliminada.IdDependencia);
                filaEliminada = temp2.filaJson;
                newfilas = temp2.newFilas;
            }
        } while (filaEliminada && hayNuevaDependencia(filaEliminada.IdDependencia));
        return newfilas;
    }

    const obtenerPapa = (idCampoAEliminar) => {
        let papaa = {};

        let papa = fichaCampos.filter(campo => campo.IdDependencia == idCampoAEliminar);
        if (papa.length > 0) {
            papaa = papa[0];
        }

        return papaa;
    };

    const eliminarTodasLasDependenciasPadre = (newfilas, idCampoAEliminar) => {
        let papa = obtenerPapa(idCampoAEliminar);

        const temp = eliminarFilaYElminarSustentoEnCasoLasOtrasFilasNoLoNecesiten(newfilas, papa.IdFichaCampo);
        let filaJson = temp.filaJson;
        newfilas = temp.newFilas;

        let filaEliminada = filaJson;

        do {
            if (filaEliminada && jsonNoEstaVacio(obtenerPapa(filaEliminada.IdFichaCampo))) {
                let temp2 = eliminarFilaYElminarSustentoEnCasoLasOtrasFilasNoLoNecesiten(newfilas, obtenerPapa(filaEliminada.IdFichaCampo).IdFichaCampo);
                filaEliminada = temp2.filaJson;
                newfilas = temp2.newFilas;
            }
        } while (filaEliminada && jsonNoEstaVacio(obtenerPapa(filaEliminada.IdFichaCampo)));
        return newfilas;
    };

    function obtenerUnicosSustentosAMostrar(newfilas) {
        let unicosSustentosAMostrar = [];

        newfilas.forEach((elemento, indice, array) => {
            if ((unicosSustentosAMostrar.indexOf(elemento.Sustento)) == -1) {
                unicosSustentosAMostrar.push(elemento.Sustento);
            }
        });
        return unicosSustentosAMostrar;
    }

    const obtenerFilasConCampoEliminado = (idCampoAEliminar) => {
        let newfilas = filas;
        newfilas = eliminarTodasLasDependenciasHijo(newfilas, idCampoAEliminar);
        newfilas = eliminarTodasLasDependenciasPadre(newfilas, idCampoAEliminar);

        let unicosSustentosAMostrar = obtenerUnicosSustentosAMostrar(newfilas);


        if ((unicosSustentosAMostrar.indexOf("DNI")) == -1) {
            setHideInputAdjuntaDNI(true);
            resetUploadDNI();
        }

        if ((unicosSustentosAMostrar.indexOf("ACTA_MATRIMONIO")) == -1) {
            setHideInputActaDeMatrimonio(true);
            resetUploadActaDeMatrimonio();
        }

        if ((unicosSustentosAMostrar.indexOf("ACTA_NACIMIENTO")) == -1) {
            setHideInputActaDeNacimiento(true);
            resetUploadActaDeNacimiento();
        }

        if ((unicosSustentosAMostrar.indexOf("CONSTANCIA_CAMBIO_CUENTA")) == -1) {
            setHideInputConstanciaCambioCuenta(true);
            resetUploadConstanciaCambioCuenta();
        }
        return newfilas;
    };

    const removeFromList = (idCampoAEliminar) => {
        let newfilas = obtenerFilasConCampoEliminado(idCampoAEliminar);
        setFilas(newfilas);
    };

    const updateValidationMessage = (fieldId, message) => {
        let id = `solicitud-cambio-validation-message-${fieldId}`;
        let element = document.getElementById(id);
        if (element) {
            element.innerHTML = message;
        } else {
            console.warn(`no existe elemento html con el id ${id}`);
        }
    };

    const filaTieneDependenciaAscendente = (fila) => {
        let papa = obtenerPapa(fila.IdFichaCampo);
        return jsonNoEstaVacio(papa) ? true : false;
    };

    async function obtenerRegexYMensajeInputDiferenteAlistas(fila) {
        let regexString = fila.ValidationString;
        let message = fila.ValidationMessage;
        if (filaTieneDependenciaAscendente(fila)) {
            console.debug("fila tiene dependencia ascendente ", fila);
            let papa = obtenerPapa(fila.IdFichaCampo);
            let valorDePapa = obtenerValorDeElementPorId(`newField-${papa.IdFichaCampo}`);
            if (!valorDePapa) {
                valorDePapa = papa.DatoActualValue;
            }
            console.debug("papa: ", papa);
            const response = await executeGetRequest(papa.UrlSolicitudDatos);
            if (responseCode200(response)) {
                let opcionesValidasComoPapas = response.data;
                const papaSeleccionado = opcionesValidasComoPapas.filter(t => t.Value == valorDePapa)[0];
                if (papaSeleccionado) {
                    regexString = papaSeleccionado.Regex;
                    message = papaSeleccionado.ValidationMessage;
                } else {
                    console.warn(`no se encontró a dependencia ascendente en la tabla, papa.IdFichaCampo: ${papa.IdFichaCampo} response papa.UrlSolicitudDatos`, response);
                }
            }
        }
        return {regexString, message};
    }

    const formularioValido = async () => {
        let formularioValidoo = true;
        console.debug("filas ", filas);
        for (const fila of filas) {
            console.debug("fila ", fila);
            if (fila.TipoDato !== "List" && fila.TipoDato !== "Calendar") {
                let {regexString, message} = await obtenerRegexYMensajeInputDiferenteAlistas(fila);
                if (regexString) {
                    let regex = new RegExp(regexString);

                    let inputNewValue = obtenerValorDeElementPorId(`newField-${fila.IdFichaCampo}`);
                    if (inputNewValue && regex.test(inputNewValue)) {
                        updateValidationMessage(fila.IdFichaCampo, "");
                    } else {
                        updateValidationMessage(fila.IdFichaCampo, message);
                        formularioValidoo = false;
                    }
                }
            }

            if (fila.TipoDato == "List") {
                let inputNewValue = obtenerValorDeElementPorId(`newField-${fila.IdFichaCampo}`);
                if (inputNewValue && inputNewValue == 0) {
                    updateValidationMessage(fila.IdFichaCampo, "Seleccionar una opción");
                    formularioValidoo = false;
                } else {
                    updateValidationMessage(fila.IdFichaCampo, "");
                }
            }

            if (fila.TipoDato == "Calendar") {
                let inputNewValue = obtenerValorDeElementPorId(`newField-${fila.IdFichaCampo}`);
                if (inputNewValue) {
                    updateValidationMessage(fila.IdFichaCampo, "");
                } else {
                    updateValidationMessage(fila.IdFichaCampo, "Seleccionar una fecha");
                    formularioValidoo = false;
                }
            }
        }


        return formularioValidoo;
    };

    const documentosRequeridosEstanAdjuntadosCorrectamente = () => {
        let adjuntosCorrectos = true;
        if (!hideInputAdjuntaDNI && (jsonEstaVacio(s3UploadFileResponseDNI) || fileNameDNI == "")) {
            toast.error("Adjunta Documento de Identidad.");
            adjuntosCorrectos = false;
        }

        if (!hideInputActaDeNacimiento && (jsonEstaVacio(s3UploadFileResponseActaDeNacimiento) || fileNameActaDeNacimiento == "")) {
            toast.error("Adjunta Acta de Nacimiento.");
            adjuntosCorrectos = false;
        }

        if (!hideInputActaDeMatrimonio && (jsonEstaVacio(s3UploadFileResponseActaDeMatrimonio) || fileNameActaDeMatrimonio == "")) {
            toast.error("Adjunta Acta de Matrimonio.");
            adjuntosCorrectos = false;
        }

        if (!hideInputConstanciaCambioCuenta && (jsonEstaVacio(s3UploadFileResponseConstanciaCambioCuenta) || fileNameConstanciaCambioCuenta == "")) {
            toast.error("Adjunta Constancia de cambio de cuenta.");
            adjuntosCorrectos = false;
        }
        return adjuntosCorrectos;
    };

    const resetForm = () => {
        document.getElementById("modal-solicitar-cambios-generic").reset();
    };

    const submitEnviarSolicitud = async (event) => {
        event.preventDefault();

        if (!documentosRequeridosEstanAdjuntadosCorrectamente()) {
            return;
        }

        if (filas && filas.length > 0) {
            let valido = await formularioValido();
            console.debug("formularioValido ", valido);
            if (valido) {
                let payload = {
                    "FichaSolicitudCampos": [],
                    "FichaSolicitudSustentos": []
                };

                filas.forEach((fila) => {
                    let textValue = "";

                    if (fila?.TipoDato == "List") {
                        let elemento = obtenerElementPorId(`newField-${fila?.IdFichaCampo}`);
                        textValue = elemento.options[elemento.selectedIndex].text;
                    }
                    let inputNewValue = "";

                    if (fila?.TipoDato === "TypeAhead") {
                        inputNewValue = liderSeleccionado?.value;
                        textValue = liderSeleccionado?.label;
                    } else {
                        inputNewValue = obtenerValorDeElementPorId(`newField-${fila?.IdFichaCampo}`);
                    }

                    let jsonField = {
                        "IdFichaCampo": fila?.IdFichaCampo,
                        "OldValue": fila?.DatoActual,
                        "newTextValue": textValue === "" ? inputNewValue : textValue,
                        "newValue": inputNewValue
                    };

                    payload.FichaSolicitudCampos.push(jsonField);
                });

                payload = agregarFileViewModelDeAdjuntoEnCasoExista(payload, s3UploadFileResponseDNI, "DNI");
                payload = agregarFileViewModelDeAdjuntoEnCasoExista(payload, s3UploadFileResponseActaDeMatrimonio, "ACTA_MATRIMONIO");
                payload = agregarFileViewModelDeAdjuntoEnCasoExista(payload, s3UploadFileResponseActaDeNacimiento, "ACTA_NACIMIENTO");
                payload = agregarFileViewModelDeAdjuntoEnCasoExista(payload, s3UploadFileResponseConstanciaCambioCuenta, "CONSTANCIA_CAMBIO_CUENTA");


                console.debug("payload ", payload);
                let response = await executePostRequest(enviarSolicitudAPI(), payload);
                closeSolicitarCambiosModal();
                if (responseCode200(response)) {
                    showUpdateConfirmationModal(response.data.UrlImage, response.data.Message);
                    resetForm();
                    reloadViewPageSeccion(idSeccionnnn);
                    askForNewBellNotifications(dispatch);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        }
    };

    function agregarFileViewModelDeAdjuntoEnCasoExista(payload, response, tipoSustento) {
        if (!(response && Object.keys(response).length === 0 && response.constructor === Object)) {
            payload.FichaSolicitudSustentos = [...payload.FichaSolicitudSustentos, {"TipoSustento": tipoSustento, "FileViewModel": response}];
        }
        return payload;
    }

    const resetUploadDNI = () => {
        setS3UploadFileResponseDNI({});
        setFileNameDNI("");
        clearInputValue(htmlElementIdInputFileDNI);
    };

    const manageUploadFileResponseDNI = (response, file) => {
        console.debug("manageUploadFileResponseDNI ", response);
        if (responseUploadFileSuccess(response)) {
            console.debug("response success");
            let s3Response = response.data.file;
            setS3UploadFileResponseDNI(s3Response);
            setFileNameDNI(file.name);
            toast.success("El archivo se cargó exitosamente");
        } else {
            console.debug("reset Upload DNI");
            resetUploadDNI();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFileDNI = async (event) => {
        try {
            console.debug("handleUploadFileDNI ", event);
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivoDNI(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivoDNI(false);
                manageUploadFileResponseDNI(response, file);
            } else {
                resetUploadDNI();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    //
    const resetUploadConstanciaCambioCuenta = () => {
        setS3UploadFileResponseConstanciaCambioCuenta({});
        setFileNameConstanciaCambioCuenta("");
        clearInputValue(htmlElementIdInputFileConstanciaCambioCuenta);
    };

    const manageUploadFileResponseConstanciaCambioCuenta = (response, file) => {
        console.debug("manageUploadFileResponseConstanciaCambioCuenta ", response);
        if (responseUploadFileSuccess(response)) {
            console.debug("response success");
            let s3Response = response.data.file;
            setS3UploadFileResponseConstanciaCambioCuenta(s3Response);
            setFileNameConstanciaCambioCuenta(file.name);
            toast.success("El archivo se cargó exitosamente");
        } else {
            console.debug("reset Upload ConstanciaCambioCuenta");
            resetUploadConstanciaCambioCuenta();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFileConstanciaCambioCuenta = async (event) => {
        try {
            console.debug("handleUploadFileConstanciaCambioCuenta ", event);
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivoConstanciaCambioCuenta(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivoConstanciaCambioCuenta(false);
                manageUploadFileResponseConstanciaCambioCuenta(response, file);
            } else {
                resetUploadConstanciaCambioCuenta();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };
    //

    const resetUploadActaDeMatrimonio = () => {
        setS3UploadFileResponseActaDeMatrimonio({});
        setFileNameActaDeMatrimonio("");
        clearInputValue(htmlElementIdInputFileActaDeMatrimonio);
    };

    const manageUploadFileResponseActaMatrimonio = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponseActaDeMatrimonio(s3Response);
            setFileNameActaDeMatrimonio(file.name);
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadActaDeMatrimonio();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFileActaMatrimonio = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivoActaDeMatrimonio(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivoActaDeMatrimonio(false);
                manageUploadFileResponseActaMatrimonio(response, file);
            } else {
                resetUploadActaDeMatrimonio();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const resetUploadActaDeNacimiento = () => {
        setS3UploadFileResponseActaDeNacimiento({});
        setFileNameActaDeNacimiento("");
        clearInputValue(htmlElementIdInputFileActaNacimiento);
    };

    const manageUploadFileResponseActaNacimiento = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponseActaDeNacimiento(s3Response);
            setFileNameActaDeNacimiento(file.name);
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadActaDeNacimiento();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFileActaNacimiento = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivoActaDeNacimiento(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivoActaDeNacimiento(false);
                manageUploadFileResponseActaNacimiento(response, file);
            } else {
                resetUploadActaDeNacimiento();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeListaDesplegable = async (e) => {
        let inputValue = e.target.value;
        let inputName = e.target.name;

        console.debug("inputValue ", inputValue);
        console.debug("name ", inputName);

        let fieldIdString = inputName.replace("newField-", "");
        let fieldIdInt = parseInt(fieldIdString);
        console.debug("fieldIdInt: ", fieldIdInt);

        if (("newField-" + PAIS_ID_FICHA_CAMPO) == inputName && idSeccionnnn == MENUS_FICHA_DATOS.datosPersonales.secciones.DatosDeNacimiento.id) {

            let paisesResponse = await executeGetRequest(getPaisesAPI());
            let idPaisPeru = obtenerPaisPeru(paisesResponse);
            console.debug({idPaisPeru});
            if (inputValue == idPaisPeru) {
                console.debug("se ha seleccionado Perú, por lo que se mostrará departamento, provincia y distrito y se esconderá ciudad");
                let filasTemp = obtenerFilasConCampoEliminado(CIUDAD_ID_FICHA_CAMPO);
                await addField(undefined, DEPARTAMENTO_ID_FICHA_CAMPO, filasTemp);

            } else {
                console.debug("se ha seleccionado País diferente a Perú, por lo que se mostrará ciudad y se esconderá departamento, provincia y distritos");
                let filasTemp = obtenerFilasConCampoEliminado(DEPARTAMENTO_ID_FICHA_CAMPO);
                await addField(undefined, CIUDAD_ID_FICHA_CAMPO, filasTemp);
            }
        } else {
            console.warn("idseccion no es nacimiento y input name no es pais");
        }
    };

    const onChangeDepartamento = async (e) => {
        let departamentoSeleccionado = e.target.value;

        let api = `Datos/GetUbigeoProvinciaByIdDepartamento/${departamentoSeleccionado}`;
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            setProvincias([firstGeneralJsonOptionSelect, ...response.data]);
        }

        setDistritos([]);
    };

    const onChangeProvincia = async (e) => {
        let provinciaSeleccionado = e.target.value;

        let api = `Datos/GetUbigeoDistritoByIdProvincia/${provinciaSeleccionado}`;
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            setDistritos([firstGeneralJsonOptionSelect, ...response.data]);
        }
    };


    const onChangeUnidadSeccionDatosEmpresa = async (e) => {
        console.info("onChangeUnidadSeccionDatosEmpresa");
        let unidadSeleccionada = e.target.value;
        let api = `Datos/GetDepartamentosbyUnidad/${unidadSeleccionada}`;
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            setDepartamentosSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
        }
    };

    const onChangeDepartamentoSeccionDatosEmpresa = async (e) => {
        console.info("onChangeDepartamentoSeccionDatosEmpresa");
        let departamentoSeleccionado = e.target.value;
        let api = `Datos/GetAreasByDepartamento/${departamentoSeleccionado}`;
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            setAresSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
        }
    };

    const onChangeAreaDatosSeccionDatosEmpresa = async (e) => {
        console.info("onChangeAreaDatosSeccionDatosEmpresa");
        let areaSeleccionada = e.target.value;
        let api = `Datos/GetSeccionesByArea/${areaSeleccionada}`;
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            setSeccionesSeccionDatosEmpresa([firstGeneralJsonOptionSelect, ...response.data]);
        }
    };

    const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
        setLiderSeleccionado(jsonValue);
    }

    return (
        <div className="ficha-modal-solicitar-cambios">
            <div className="modalHeader">
                <div className="title">
                    <p className="font-gothan-bold">Solicitud de actualización de datos a GDH</p>
                </div>
                <div className="icon">
                    <FontAwesomeIcon icon='times-circle' className="hand-pointer" transform="left-9" onClick={closeSolicitarCambiosModal}/>
                </div>
            </div>
            <div className="modalContent">
                <form id="modal-solicitar-cambios-generic">
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div>
                                <label className="font-gothan-light blue-standard-color">Elige una campo de la lista para modificar datos: </label>
                            </div>
                            <div>

                                {fichaCamposDropdown &&
                                <select defaultValue={idFichaCampoSeleccionado} value={idFichaCampoSeleccionado} className="form-control ficha-form-select-sustento-solicitud-cambio" onChange={handleFichaCampoChange}>
                                    fichaCamposDropdown &&
                                    {
                                        fichaCamposDropdown.map((option) => (
                                                <option key={option.IdFichaCampo} value={option.IdFichaCampo}>{option.NombreCampo}</option>
                                            )
                                        )
                                    }
                                </select>
                                }
                                <button className="btn btn-primary generic-button agregar-campo-sustento-solicitud-cambio-position" onClick={(e) => addField(e, idFichaCampoSeleccionado, filas)}> Agregar</button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-borderless">
                        <thead>
                        <tr className="d-flex">
                            <th scope="col" className="font-gothan-light blue-standard-color col-3">Campo a actualizar</th>
                            <th scope="col" className="font-gothan-light blue-standard-color col-4">Dato actual</th>
                            <th scope="col" className="font-gothan-light blue-standard-color col-4">Debería decir</th>
                            <th scope="col" className="font-gothan-light blue-standard-color col-1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {filas && filas.map((fila) => (
                            <tr key={fila.IdFichaCampo} id="camposAgregados" className="d-flex">
                                <td className="column p-0 col-3">{fila.NombreCampo}</td>
                                <td className="column p-0 col-4">
                                    <input className="form-control" value={fila.DatoActual} disabled></input>
                                </td>
                                <td className="column p-0 pl-2 col-4">
                                    {fila.TipoDato == "Text" && <input type="text" id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`}></input>}
                                    {fila.TipoDato == "Boolean" && <input type="checkbox" id={`newField-${fila.IdFichaCampo}`} className="form-check-input" name={`newField-${fila.IdFichaCampo}`}></input>}
                                    {fila.TipoDato == "Calendar" && <input type="date" min={currentYear - 70 + "-01-01"} max={currentYear - 18 + "-12-31"} id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`}></input>}


                                    {fila.TipoDato == "List" && fila.dropDownList && fila.NombreCampo !== "Departamento de Nacimiento" && fila.NombreCampo !== "Provincia de Nacimiento" && fila.NombreCampo !== "Distrito de Nacimiento" && fila.NombreCampo !== "División" && fila.NombreCampo !== "Gerencia" && fila.NombreCampo !== "Área" && fila.NombreCampo !== "Sección" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeListaDesplegable}>
                                        {fila.dropDownList.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "MultiList" && fila.dropDownList &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeListaDesplegable}>
                                        {fila.dropDownList.map((option) => (
                                                <option key={option.Value} value={option.Value}><input type="checkbox" id="one">{option.Text}</input></option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && departamentos && fila.NombreCampo == "Departamento de Nacimiento" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeDepartamento}>
                                        {departamentos.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && provincias && fila.NombreCampo == "Provincia de Nacimiento" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeProvincia}>
                                        {provincias.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && fila.NombreCampo == "Distrito de Nacimiento" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`}>
                                        {distritos.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }


                                    {fila.TipoDato == "List" && unidadesSeccionDatosEmpresa && fila.NombreCampo == "División" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeUnidadSeccionDatosEmpresa}>
                                        {unidadesSeccionDatosEmpresa.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && departamentosSeccionDatosEmpresa && fila.NombreCampo == "Gerencia" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeDepartamentoSeccionDatosEmpresa}>
                                        {departamentosSeccionDatosEmpresa.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && areasDatosSeccionDatosEmpresa && fila.NombreCampo == "Área" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`} onChange={onChangeAreaDatosSeccionDatosEmpresa}>
                                        {areasDatosSeccionDatosEmpresa.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "List" && fila.NombreCampo == "Sección" &&
                                    <select id={`newField-${fila.IdFichaCampo}`} className="form-control" name={`newField-${fila.IdFichaCampo}`}>
                                        {seccionesSeccionDatosEmpresa.map((option) => (
                                                <option key={option.Value} value={option.Value}>{option.Text}</option>
                                            )
                                        )}
                                    </select>
                                    }

                                    {fila.TipoDato == "TypeAhead" && fila.NombreCampo == "Líder" &&
                                    <AsyncSelectInputGET api={getColaborador} placeholder="Nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal}/>
                                    }


                                    <p className="ficha-form-input-validation-message" id={"solicitud-cambio-validation-message-" + fila.IdFichaCampo}></p>
                                </td>
                                <td><FontAwesomeIcon icon='trash-alt' transform="left-2" className="hand-pointer " onClick={() => {
                                    removeFromList(fila.IdFichaCampo);
                                }}/></td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>

                    <div>
                        <div hidden={hideInputAdjuntaDNI}>
                            <div>
                                <label className="custom-file-upload upload-file-sustento-solicitud-cambio">
                                    <input type="file" id={htmlElementIdInputFileDNI} accept=".pdf,.jpg,.png" onChange={handleUploadFileDNI}></input>
                                    <p className='btn btn-primary generic-button-ficha'>Adjunta tu Documento de Identidad</p>  {fileNameDNI} {subiendoArchivoDNI && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}
                                </label>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"> <strong>*Archivos de 8MB como máximo.</strong></label>
                            </div>
                        </div>
                        <div hidden={hideInputConstanciaCambioCuenta}>
                            <div>
                                <label className="custom-file-upload upload-file-sustento-solicitud-cambio">
                                    <input type="file" id={htmlElementIdInputFileConstanciaCambioCuenta} accept=".pdf,.jpg,.png" onChange={handleUploadFileConstanciaCambioCuenta}></input>
                                    <p className='btn btn-primary generic-button-ficha'>Adjunta constancia de cambio de cuenta</p>  {fileNameConstanciaCambioCuenta} {subiendoArchivoConstanciaCambioCuenta && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}
                                </label>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"> <strong>*Archivos de 8MB como máximo.</strong></label>
                            </div>
                        </div>
                        <div hidden={hideInputActaDeMatrimonio}>
                            <div>
                                <label className="custom-file-upload upload-file-sustento-solicitud-cambio">
                                    <input type="file" id={htmlElementIdInputFileActaDeMatrimonio} accept=".pdf,.jpg,.png" onChange={handleUploadFileActaMatrimonio}></input>
                                    <p className='btn btn-primary generic-button-ficha'>Adjunta tu Acta de Matrimonio</p>  {fileNameActaDeMatrimonio} {subiendoArchivoActaDeMatrimonio && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}
                                </label>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"><strong>*Archivos de 8MB como máximo.</strong></label>
                            </div>
                        </div>
                        <div hidden={hideInputActaDeNacimiento}>
                            <div>
                                <label className="custom-file-upload upload-file-sustento-solicitud-cambio">
                                    <input type="file" id={htmlElementIdInputFileActaNacimiento} accept=".pdf,.jpg,.png" onChange={handleUploadFileActaNacimiento}></input>
                                    <p className='btn btn-primary generic-button-ficha'>Adjunta tu Acta de Nacimiento</p>  {fileNameActaDeNacimiento} {subiendoArchivoActaDeNacimiento && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}
                                </label>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"><strong>*Archivos de 8MB como máximo.</strong></label>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="modal-footer">
                    <div className="message font-gothan-light">
                        <strong>
                            <p>** Recibirás una notificación de GDH con una respuesta sobre tu solicitud de cambio. </p>
                        </strong>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submitEnviarSolicitud}> Enviar solicitud</button>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default ModalSolicitarCambios;
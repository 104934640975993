import React from "react";
import PropTypes from 'prop-types';
import { DivInput } from "../atoms/DivInput";

const InputBool = ({
  label,
  textInput = "No / Sí",
  name,
  value,
  onChange,
  sectionName,
  isDisabled,
  isRequired,
  colLabel=4,
  colInput=8,
  direction = "row",
}) => {
  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        <div className="custom-switch mt-1 ml-3">
          <input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={value ?? false}
            value={value ?? false}
            disabled={isDisabled}
            id={`admin-input-bool-select-${sectionName}-${name}`}
            className="custom-control-input hand-pointer"
          ></input>
          <label
            className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" style={{paddingTop: "2px"}}
            htmlFor={`admin-input-bool-select-${sectionName}-${name}`}
          >
            {textInput}
          </label>
        </div>
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </DivInput>
  );
};

export default InputBool;
import React from "react";

export const AlertErrorMaxFileSizeMessage = () => (
    <div className="toast-error-max-file-size">
        <p>Solo son válidos archivos jpg, jpeg, png o pdf de máximo 8MB.</p>
        <p>Le recomendamos comprimir el archivo en la siguiente página web: <a href="https://www.compress2go.com/es" target="_blank" className="toast-error-custom-link">Link</a></p>
    </div>
);

export const AlertErrorMaxExcelFileSizeMessage = () => (
    <div className="toast-error-max-file-size">
        Solo son válidos archivos xlsx de máximo 8MB.
    </div>
);

export const AlertErrorMaxPdfFileSizeMessage = () => (
    <div className="toast-error-max-file-size">
        Solo son válidos archivos pdf.
    </div>
);
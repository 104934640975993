import React, { useState, useEffect, useContext } from "react";
import store from 'store';
import axios from "axios";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { jsonNoEstaVacio } from "../../../helper/utils";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { AuthContext } from "../../../hooks/context/authContext";
import ModalMensaje from "../../../components/modalMensaje/ModalMensaje";
import PageHeader from "../../../components/pageHeader/PageHeader";
import DatosPersonalesEditarGlobal from "./tabs/DatosPersonalesEditarGlobal";
import { IS_AUTH_KEY, PROFILE, TOKEN_KEY, USER_DATA_KEY } from "../../../constants";
import { getDocumentosEmpleadoAPI, GetBaseNuevoIngresoGestionAPI } from "../../../consumers/backendApisUrls";

const EditarFichaDatosGlobal = (props) => {

  const searchParams = new URLSearchParams(props.location.search);
  const token = searchParams.get('token');
  const idNuevosIngresosEmpleado = 0;
  const ERROR_CODES = [401, 403];
  const MENSAJE_EXPIRO = "El link de acceso para completar tus documentos de ingreso ha caducado, contactacte con Fiorella Flores al correo fflores@innovaschools.edu.pe";
  const IMAGE_LOCK = "https://intranet.innovaschools.edu.pe/Content/image/lock_1.png";
  
  const {dispatch, state} = useContext(AuthContext);
  const [permissions, setPermissions] = useState({});
  const [options, setOptions] = useState([]);
  const [modalEnable, setModalEnable] = useState({isEnable: false});
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [executeManageGet] = useManageGetRequest();

  const setTokenOnAuth = () => {
    if(token) {
      dispatch({
        type: 'PUBLIC_TOKEN',
        payload: {
          token: token,
        }
      });
      setIsPageLoaded(true);
    }
  }

  const init = async () => {
    try {
      let firstEnter = await getPermissions();
      if(jsonNoEstaVacio(firstEnter)) manageSearch();
    } catch (error) {
      console.error(error);
    }
  }

  const getPermissions = async () => {
    let response = await executeGetPublic(GetBaseNuevoIngresoGestionAPI(0), state);
    let permissions = {};
    if(responseCode200(response)){
      permissions = response.data;
      setPermissions(permissions);
      return permissions;
    }
    removeLocalStorage();
    let messageExpiro = MENSAJE_EXPIRO;
    let message = ERROR_CODES.includes(response.response.status) ? messageExpiro : "Ha ocurrido un error";
    setModalEnable({isEnable: true, component: ModalMensaje, data: {modalMensaje: message, modalImage: IMAGE_LOCK, styleFicha: {color: "red"}, hasBackButton: false}});
    return permissions;
  }

  const manageSearch = async () => {
    try {
      const api = getDocumentosEmpleadoAPI(0);
      await executeManageGet(api, successManageSearchCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const successManageSearchCallback = (res) => {
    setOptions(res.data?.Documentos);
  }

  const removeLocalStorage = () => {
    store.set(IS_AUTH_KEY, false);
    store.remove(USER_DATA_KEY);
    store.remove(TOKEN_KEY);
    store.remove(PROFILE);
  }

  useEffect(() => {
    setTokenOnAuth();
  }, [token])

  useDidMountEffect(async () => {
    init();
  }, [isPageLoaded])

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnable.isEnable && 
          <div className="modal-contactanos modal-response-ficha-background">
              <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} refreshPage={manageSearch} {...modalEnable.data}/>
          </div>
        }
        <PageHeader title="Ficha de Datos y Documentos de Ingreso" />
      </div>
      <hr className="my-0"/>
      {(options.length > 0) &&
        <DatosPersonalesEditarGlobal
          idNuevosIngresosEmpleado={idNuevosIngresosEmpleado}
          manageSearchListaDocumentos={manageSearch}
          documents={options}
          isColaborador
          isOnlyDocuments={permissions.MostrarSoloDocumentos}
          isObservated={permissions?.EstadoDocumentoEsObservado}
          puedeEnviar={permissions?.PuedeEnviar}
          fichaStep={permissions?.PasoActual}
          tieneHijos={permissions?.TieneHijos}
        />
      }
    </div>
  );
};

export default EditarFichaDatosGlobal;

export const executeGetPublic = (api, state) => {
  const urlApiBackend = process.env.REACT_APP_URL_API_BACKEND;

  if (api) {
    return axios.get(`${urlApiBackend}${api}`, {
      headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST',
        Authorization: 'Bearer ' + state.token,
      },
    }).catch(response => {
      console.error("error response", response);
      return response;
    });
  }
}
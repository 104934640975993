import React from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VerRequerimiento from './modales/VerRequerimiento'
import EditarRequerimiento from './modales/EditarRequerimiento'
import { ESTADOS } from '../../constants/hardCodedConstants'

const TableWebBody = ({ filas, setModalEnabled, handleToggleEsControl }) => {
  return (
    <tbody className="listar-tbody">
      {filas.map((fila,i)=> (
        <tr key={`${i}-${fila.IdRequerimientoPersonal}`}>
          <td className="text-center">{fila?.IdRequerimientoPersonal}</td>
          <td className="text-center">{fila?.FechaRegistro}</td>
          <td className="text-center">{fila?.FechaAprobacion}</td>
          <td className="text-center">{fila?.FechaCompletado}</td>
          <td className="text-center">{fila?.CantidadDiasTiempoAtencion}</td>
          <td className="">{fila?.Sede}</td>
          <td className="">{fila?.Division}</td>
          <td className="">{fila?.Gerencia}</td>
          <td className="">{fila?.Area}</td>
          <td className="text-center">{fila?.Cargo}</td>
          <td className="text-center">{fila?.EspecialidadSeleccion}</td>
          <td className="text-center">{fila?.TipoEmpleado }</td>
          <td className="text-center">{fila?.AvanceRequerimiento}</td>
          <td className="text-center">{fila?.EstadoAprobacion}</td>
          <td className="text-center">{fila?.EstadoProceso}</td>
          <td className="">{fila?.Colaborador}</td>
          <td className="text-center">
            <input className='hand-pointer' type='checkbox' checked={fila.EsControl === ESTADOS.SI} value={fila.EsControl} onChange={() => handleToggleEsControl(fila.IdRequerimientoPersonal)}/>
          </td>
          <td className="text-center">
            <div className="d-flex justify-content-center blue_innova_text" style={{ gap: "10px" }}>
              {fila?.TieneCeseRechazado &&
                <GenericTooltip id={`ceseRechazado-${fila.IdRequerimientoPersonal}`}/>
              }
              <FontAwesomeIcon
                icon={faEye}
                size="lg"
                className='hand-pointer blue_innova_text'
                title="Ver Detalles"
                onClick={() => {
                  setModalEnabled({isEnable: true, component: VerRequerimiento, data: {id: fila.IdRequerimientoPersonal }})
                }}
              />
              {fila?.PuedeEditar && 
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  className='hand-pointer'
                  title="Editar"
                  onClick={() => {
                    setModalEnabled({isEnable: true, component: EditarRequerimiento, data: {id: fila.IdRequerimientoPersonal }})
                  }}
                />
              }
              {fila?.PuedeVerFicha && 
                <Link to={`/fichadedatos/fromGestionFicha/${fila.IdEmpleadoNuevoIngreso}`}>
                  <FontAwesomeIcon
                    icon="user"
                    size="lg"
                    className='hand-pointer blue_innova_text'
                    title="Ver Ficha"
                  />
                </Link>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody;

const GenericTooltip = ({id, text}) => (
  <>
    <FontAwesomeIcon
      data-tip data-for={id}
      size='lg'
      icon='exclamation-triangle'
      style={{color: "#b3a11a"}}
    />
    <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
      Renuncia Rechazada
    </ReactTooltip>
  </>
)
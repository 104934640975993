import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetallePeriodoPlanilla from "./modales/DetallePeriodoPlanilla";

const TableWebBody = ({
    filas,
    showModalVerSolicitudVacaciones,
    showModalEliminarSolicitudVacaciones,
    showModalAprobarRechazarSolicitudVacaciones,
    showModalReasignarSolicitudVacaciones,
    showModalAgregarNotaSolicitudVacaciones,
    setModalEnabled,
    isAdmin
}) => (
    <tbody className="listar-tbody">
        {filas.map((fila, i) => (
            <tr key={i}>
                {isAdmin && <td className="" style={{width: "20px"}}><div style={{width: "20px"}}>{fila?.IdSolicitudMovimiento}</div></td>}
                <td className="col-1"><div style={{width: "90px"}}>{fila?.FechaRegistro}</div></td>
                <td className="col-1">
                    <div className="d-flex flex-column" style={{width: "120px"}}>
                        <div>{fila?.ApellidoEmpleado}</div>
                        <div>{fila?.NombreEmpleado}</div>
                    </div>
                </td>
                <td className="col-2">{fila?.AreaEmpleado}</td>
                {!isAdmin && <td className="col-1">{fila?.TipoMovimiento}</td>}
                <td className="col-1">{fila?.CargoEmpleado}</td>
                <td className="col-1">{fila?.EstadoProceso}</td>
                {!isAdmin && <td className="col-1">{fila?.EstadoRegistro}</td>}
                <td className="col-1">
                    <div className="d-flex flex-column" style={{width: "190px"}}>
                        {fila?.PeriodosFechas && (
                            <div>
                            {fila.PeriodosFechas.map((fecha, index) => (
                                    <p style={{"margin-bottom":"1px"}} key={index}>{fecha}</p>
                                ))}
                            </div>
                        )}
                    </div>
                </td>
                <td className=""><div style={{width: "60px"}}>{fila?.CantidadDias}</div></td>
                <td className="col-1">{fila?.DiasUtiles}</td>
                <td className="col-1">{fila?.DiasNoUtiles}</td>
                <td className="col-1"><div style={{width: "90px"}}>{fila?.Nivel}</div></td>
                <td className=""><div style={{width: "180px"}}>{fila?.Aprobador}</div></td>
                <td className="col-1">
                    <div style={{width: "112px"}}>
                    <div className="row">
                        <div className="col-1" style={{margin: "2px 2px 0 2px"}}>
                            {fila.PuedeAgregarNotas &&
                                <div className="d-flex"
                                onClick={() => showModalAgregarNotaSolicitudVacaciones(fila?.IdSolicitudMovimiento, fila?.NombreEmpleado)}
                                >
                                    <span className="badge badge-success badge-number-ceses">
                                        {fila?.CantidadNotas ?? 0}
                                    </span>
                                    <FontAwesomeIcon icon='comment' transform="left-8" size="lg" className="hand-pointer  blue-standard-color" title="Registrar una Nota"/>
                                </div>
                            }
                        </div>
                    {isAdmin
                    ?   <>
                            <div className="col-1">
                                <FontAwesomeIcon icon='table' size="lg" className="hand-pointer" title="Ver Solicitud" onClick={() => showModalAprobarRechazarSolicitudVacaciones(fila?.IdSolicitudMovimiento, fila?.PeriodoVacaciones)}/>
                            </div>
                            {fila.PuedeReasignar && 
                                <div className="col-1">
                                    <FontAwesomeIcon icon='male' size="lg" className="hand-pointer" title="Reasignar Solicitud" onClick={() => showModalReasignarSolicitudVacaciones(fila?.IdSolicitudMovimiento, fila?.NombreEmpleado, fila?.Aprobador, fila?.PeriodoVacaciones)}/>
                                </div>
                            }
                            {fila.PuedeEditarFechas && 
                                <div className="col-1">
                                    <FontAwesomeIcon icon='info-circle' size="lg" className="hand-pointer" title="Detalle periodo de planilla" onClick={() => setModalEnabled({ isEnable: true, component: DetallePeriodoPlanilla, data:{ idSolicitudMovimiento: fila?.IdSolicitudMovimiento }})}/>
                                </div>
                            }
                        </>
                    :   <>
                            <div className="col-1">
                                <FontAwesomeIcon icon='table' size="lg" className="hand-pointer" title="Ver detalle" onClick={() => showModalVerSolicitudVacaciones(fila?.IdSolicitudMovimiento)}/>
                            </div>
                            <div className="col-1">
                                {fila?.PuedeEditar &&
                                <FontAwesomeIcon icon={fila.PuedeActivar ? 'circle-notch' : 'ban'}  size="lg" className="hand-pointer" title={fila.PuedeActivar ? 'Activar' : 'Anular'} onClick={() => showModalEliminarSolicitudVacaciones(fila?.IdSolicitudMovimiento, fila?.PeriodoVacaciones, fila?.PuedeActivar)}/>
                                }
                            </div>
                        </>
                    }
                        </div>
                    </div>
                </td>
            </tr>
        ))}
    </tbody>
)

export default TableWebBody;

import React from 'react';

const PageHeader = ({title}) => {
    return (
        <div className="page-header-style">
            <span className='menuSaludoTexto'>{title}</span>
        </div>
    )
};
export default PageHeader;

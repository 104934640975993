import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "../../hooks/useForm/UseForm";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {clearValidationMessage, updateFormValidationMessage} from "../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {isTrue, mapDropDownOptionsWithDescriptionFromBackendToSelectOptionsNeededForLibraryGeneric, notNullAndNotUndefinded} from "../../helper/utils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {enviarMensajeContactanosAPI, getContactanosInfoAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import SelectInput from "../Select/SelectInput";

const ContactanosModal = ({showContactanosModal}) => {
    const [executePostRequest] = usePostRequest();
    const seccionName = "contactanos";

    let initialValues = {
        Sede: "",
        Cargo: "",
        IdTema: 0,
        Asunto: "",
        Mensaje: ""
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();

    const [temasOptions, setTemasOptions] = useState([]);
    const [jsonSelectedTemaOption, setJsonSelectedTemaOption] = useState({});


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        console.debug("init");
        const response = await executeGetRequest(getContactanosInfoAPI());
        if (responseCode200(response)) {
            const jsonResponse = response.data;
            let sede = jsonResponse.data.Sede;
            let cargo = jsonResponse.data.Cargo;

            let formInputValues = {
                Sede: sede,
                Cargo: cargo,
                IdTema: 0,
                Asunto: "",
                Mensaje: ""
            };

            setFormValues(formInputValues);

            if (jsonResponse?.dropdowns?.temas) {
                let temp = mapDropDownOptionsWithDescriptionFromBackendToSelectOptionsNeededForLibraryGeneric(jsonResponse?.dropdowns?.temas, "Value", "Text");
                setTemasOptions(temp);
            }

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const closeSolicitarCambiosModal = () => {
        showContactanosModal();
        setFormValues({...formValues, IdTema: 0, Asunto: "", Mensaje: ""});
        setJsonSelectedTemaOption({value:undefined});
    };

    const formularioValido = async () => {
        let idTemaEsValido = validarTemaSeleccionado("IdTema");
        let asuntoEsValido = campoValidoo("Asunto", "", 60);
        let mensajeEsValido = campoValidoo("Mensaje", "", 500);

        return (isTrue(idTemaEsValido) && isTrue(asuntoEsValido) && isTrue(mensajeEsValido));
    };

    const validarTemaSeleccionado = (formKey) => {
        if (jsonSelectedTemaOption?.value!== undefined){
            clearValidationMessage(formKey, seccionName);
            return true;
        }else{
            updateValidationMessage(formKey, "Este campo es obligatorio");
            return false;
        }
    }

    const campoValidoo = (formKey, empty, maxlength) => {
        let value = formValues[formKey];
        let campoValido = true;
        if (value == empty) {
            updateValidationMessage(formKey, "Este campo es obligatorio");
            campoValido = false;
        } else {
            if (notNullAndNotUndefinded(maxlength) && value.length > maxlength) {
                updateValidationMessage(formKey, `Ingresar máximo ${maxlength} caracteres`);
                campoValido = false;
            } else {
                clearValidationMessage(formKey, seccionName);
            }
        }
        return campoValido;
    };

    const updateValidationMessage = (keyField, message) => {
        let htmlElementId = `ficha-edit-${seccionName}-${keyField}-validation-message`;
        updateFormValidationMessage(htmlElementId, message);
    };

    const submitEnviarSolicitud = async (event) => {
        event.preventDefault();
        let formValido = await formularioValido();

        if (formValido) {
            let payload = {
                "IdTema": jsonSelectedTemaOption?.value,
                "Asunto": formValues["Asunto"],
                "Mensaje": formValues["Mensaje"]
            };
            console.debug("payload ", payload);
            let response = await executePostRequest(enviarMensajeContactanosAPI(), payload);
            console.debug("response ", response);
            if (responseCode200(response)) {
                toast.success("Mensaje enviado con éxito");
                closeSolicitarCambiosModal();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    return (
        <div className="ficha-modal-solicitar-cambios" style={{'width': '800px'}}>
            <div className="modalHeader">
                <div className="title">
                    <p className="font-gothan-bold">GDH te escucha</p>
                </div>
                <div className="icon">
                    <FontAwesomeIcon icon='times-circle' className="hand-pointer" transform="left-9" onClick={() => closeSolicitarCambiosModal()}/>
                </div>
            </div>
            <div className="modalContent">
                <a href="/tutoriales" className="hand-pointer text-link-appereance" target="_blank" style={{"marginLeft": '15px'}}>Zona de Tutoriales</a>
                <form id="modal-solicitar-cambios-generic">
                    <div className="form-group row form-input-modal-contactanos" style={{'marginTop': '35px'}}>
                        <label className="col-sm-2 col-form-label">Sede</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" name="Sede" value={formValues.Sede} onChange={handleChange} disabled></input>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-contactanos-Sede-validation-message"></p>
                        </div>
                    </div>
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-2 col-form-label">Cargo</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" name="Cargo" value={formValues.Cargo} onChange={handleChange} disabled></input>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-contactanos-Cargo-validation-message"></p>
                        </div>
                    </div>
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-2 col-form-label">Tema<span className="required">*</span></label>
                        <div className="col-sm-10">
                            <SelectInput options={temasOptions} jsonSelectedValue={jsonSelectedTemaOption} setJsonSelectedValue={setJsonSelectedTemaOption} placeholder={"Seleccionar un tema"} />
                            <p className="ficha-form-input-validation-message" id="ficha-edit-contactanos-IdTema-validation-message"></p>
                        </div>
                    </div>
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-2 col-form-label">Asunto<span className="required">*</span></label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" name="Asunto" value={formValues.Asunto} onChange={handleChange}></input>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-contactanos-Asunto-validation-message"></p>
                        </div>
                    </div>
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-2 col-form-label">Mensaje<span className="required">*</span></label>
                        <div className="col-sm-10">
                            <textarea className="form-control" rows="3" name="Mensaje" onChange={handleChange} value={formValues.Mensaje}></textarea>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-contactanos-Mensaje-validation-message"></p>
                        </div>
                    </div>
                </form>

                <div className="modal-footer" style={{'marginTop': '15px'}}>
                    <div className="message font-gothan-light" style={{'width': '500px'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                        <strong>
                            <p>**Tu mensaje llegará a las personas de GDH que más rápido puedan atender tu consulta y se contactarán contigo vía Gmail.</p>
                        </strong>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary" onClick={submitEnviarSolicitud}> Enviar mensaje</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactanosModal;

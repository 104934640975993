import React, { useState, useEffect } from 'react'
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { NUEVOS_INGRESOS_FICHA_STEPS } from '../../../constants/hardCodedConstants';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import GenericModal from '../../../components/modal/GenericModal';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import DatosPersonalesEditarGlobal from '../../gestionNuevosIngresos/ficha/tabs/DatosPersonalesEditarGlobal';
import { getDocumentosEmpleadoAPI, getNuevosIngresosEmpleadoIdByIdEmpleadoFlujoAPI } from '../../../consumers/backendApisUrls';

const DocumentosPendientesModal = ({ toggleModal, manageSearch: manageSearchActividadesPendientes, idEmpleadoFlujoActividad, idEmpleadoFlujo }) => {

  const STEP_SECOND_VALIDATION = 5;

  const [formValues, setFormValues] = useState({});
  const [documents, setDocuments] = useState([]);

  const [executeGet] = useGetRequest();

  const init = async () => {
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
  }
  
  const manageSearch = async () => {
    const apiId = getNuevosIngresosEmpleadoIdByIdEmpleadoFlujoAPI(idEmpleadoFlujo);
    let nuevoIngresoEmpleado = await executeGet(apiId);
    if (responseCode200(nuevoIngresoEmpleado)) {
      setFormValues(nuevoIngresoEmpleado.data);
      const api = getDocumentosEmpleadoAPI(nuevoIngresoEmpleado.data.NuevosIngresosEmpleadoId);
      let documents = await executeGet(api);
      if(responseCode200(documents)){
        setDocuments(documents.data?.Documentos);
      } else {
        mostrarMensajeDeErrorConToast(documents);
        toggleModal();  
      }
    } else {
      mostrarMensajeDeErrorConToast(nuevoIngresoEmpleado);
      toggleModal();
    }
  }

  useEffect(() => {
    init();
  }, [])

  if(!(documents.length > 0)) return <SpinnerLoading />

  return (
    <GenericModal showModal={toggleModal} titulo="Documentos pendientes">
      <DatosPersonalesEditarGlobal
        idNuevosIngresosEmpleado={formValues.NuevosIngresosEmpleadoId} 
        manageSearchListaDocumentos={manageSearch} 
        documents={documents}
        isOnlyDocuments
        isColaborador
        isActividadPendiente
        widthDatosGlobal="w-100" 
        toggleParentModal={toggleModal}
        manageSearchActividadesPendientes={manageSearchActividadesPendientes}
        fichaStep={formValues.IdPaso}
        tieneHijos={formValues?.TieneHijos}
        // initStep={formValues.IdPaso > STEP_SECOND_VALIDATION ? NUEVOS_INGRESOS_FICHA_STEPS.stepSeven.id : NUEVOS_INGRESOS_FICHA_STEPS.stepFive.id}
      />
    </GenericModal>
  )
}

export default DocumentosPendientesModal
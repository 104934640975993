import React from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsignarEquipos from './ficha/modales/AsignarEquipos'
import ReasignarRequerimiento from './ficha/tabs/SectionsEdit/modales/ReasignarRequerimiento'
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest'
import { faEnvelope, faPaperPlane, faPeopleArrows, faToolbox, faUser, faUserCheck, faUserClock } from '@fortawesome/free-solid-svg-icons'
import { activarUsuarioEmpleadoNuevosIngresosAdminAPI, ejecutarActividadInicioLaboresOnboardingAPI, enviarNotificacionEncuestaDocentesNuevosAPI, generarActividadActualizarCuentaCorreoOnboardingAPI, reenviarTokenNuevoIngresoAPI } from '../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled, manageSearch, isShowPriorityFilter, hasAdminRol, hasSistemasRol }) => {

  const [executePost] = useManagePostRequest();

  const handleSendCorreo = async (id) => {
    const api = reenviarTokenNuevoIngresoAPI(id);
    await executePost(api, {}, () => manageSearch());
  }

  const handleEmpleadoAction = async (idEmpleado, api) => {
    await executePost(api(idEmpleado), {}, () => manageSearch());
  }

  return (
    <tbody className="listar-tbody">
      {filas.map((fila,i)=> (
        <tr key={`${i}-${fila.IdNuevosIngresosEmpleado}`}>
          {isShowPriorityFilter && 
            <td className="text-center" style={{borderLeft: `8px solid ${fila?.ColorPrioridad}`}}></td>
          }
          <td className="text-center">{fila?.FechaRegistro}</td>
          <td className="">{fila?.Cargo}</td>
          <td className="">{fila?.Sede}</td>
          <td className="">
            <div className='d-flex flex-column'>
              <label className='m-0'>
                {fila?.Nombres}
              </label>
              <label className='m-0'>
                {fila?.Apellidos}
              </label>
              <label className='m-0'>
                {fila?.TipoDocumento} - {fila?.NumeroDocumento}
              </label>
            </div>
          </td>
          {!hasSistemasRol && <td className="text-center">{fila?.FechaIngreso}</td>}
          <td className="text-center">{fila?.FechaIngresoReal}</td>
          <td className="">
            <div className='d-flex flex-column'>
              <label className='m-0'>
                {fila?.Celular}
              </label>
              <label className='m-0'>
                {fila?.Correo}
              </label>
            </div>  
          </td>
          {!hasSistemasRol && 
            <>
              <td className="text-center">{fila?.EstadoFicha}</td>
              <td className="text-center">{fila?.EstadoDocumento}</td>
            </>
          }
          <td className="text-center">{fila?.EstadoEmpleado}</td>
          {!hasSistemasRol && <td className="text-center"><div style={{width: "90px"}}>{fila?.Paso}</div></td>}
          <td className="text-center">{fila?.Responsable}</td>
          <td className="text-center">{fila?.AsignacionEquipoText ?? "-"}</td>
          <td className="text-center">
            <div className="d-flex justify-content-start align-items-center">
              {(fila?.AsignacionEquipoValue || fila?.EsSupervisorSoporteOAdmin || (fila?.AsignacionEquipoValue && fila?.EsAsistenteSoporte)) &&
                <FontAwesomeIcon 
                  icon={faToolbox}
                  className="hand-pointer blue-standard-color"
                  title="Asignar Equipos"
                  size='lg'
                  onClick={() => setModalEnabled({ isEnable: true, component: AsignarEquipos, data: { EsSupervisorSoporteOAdmin: fila?.EsSupervisorSoporteOAdmin, EsAsistenteSoporte: fila?.EsAsistenteSoporte, AsignacionEquipoValue: fila.AsignacionEquipoValue || false, EquiposAsignados: fila.EquiposAsignados?.length ? fila.EquiposAsignados.split(",") : [], IdNuevosIngresosEmpleado: fila.IdNuevosIngresosEmpleado }})}
                />
              }
              {hasAdminRol &&
                <Link to={`/adminNuevosIngresos/EditarNuevoIngreso/${fila.IdNuevosIngresosEmpleado}`}>
                  <FontAwesomeIcon
                    icon={faUser}
                    className='hand-pointer ml-3 blue-standard-color'
                    title="Editar ficha"
                    size='lg'
                  />
                </Link>
              }
              {(fila?.PuedeReenviar && hasAdminRol) &&
                <FontAwesomeIcon 
                  icon={faPaperPlane}
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Enviar Correo"
                  size='lg'
                  onClick={() => handleSendCorreo(fila.IdNuevosIngresosEmpleado)}
                />
              }
              {(fila?.PuedeActivarUsuario && hasAdminRol) &&
                <FontAwesomeIcon 
                  icon={faUserClock}
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Activar Usuario"
                  size='lg'
                  onClick={() => handleEmpleadoAction(fila.IdEmpleado, activarUsuarioEmpleadoNuevosIngresosAdminAPI)}
                />
              }
              {(fila?.PuedeEjecutarActividadInicioLaboresOnboarding && hasAdminRol) &&
                <FontAwesomeIcon 
                  icon={faUserCheck}
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Ejecutar Inicio de Labores"
                  size='lg'
                  onClick={() => handleEmpleadoAction(fila.IdEmpleado, ejecutarActividadInicioLaboresOnboardingAPI)}
                />
              }
              {(fila?.PuedeCrearActividadDeActualizacionCorreoOnboarding && hasAdminRol) &&
                <FontAwesomeIcon 
                  icon={faEnvelope}
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Actualizar cuenta correo"
                  size='lg'
                  onClick={() => handleEmpleadoAction(fila.IdEmpleado, generarActividadActualizarCuentaCorreoOnboardingAPI)}
                />
              }
              {fila?.PuedeEnviarNotificacionEncuestaDocentesNuevos &&
                <FontAwesomeIcon 
                  icon="mail-bulk"
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Enviar notificación de encuesta a docentes nuevos"
                  size='lg'
                  onClick={() => handleEmpleadoAction(fila.IdEmpleado, enviarNotificacionEncuestaDocentesNuevosAPI)}
                />
              }
              {fila?.MotivoDesiste && 
                <GenericTooltip id={`MotivoRechazo-${fila.IdNuevosIngresosEmpleado}`} text={fila.MotivoDesiste}/>
              }
              {fila?.PuedeReasignarRequerimiento &&
                <FontAwesomeIcon 
                  icon={faPeopleArrows}
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Reasignar requerimiento"
                  size='lg'
                  onClick={() => setModalEnabled({ isEnable: true, component: ReasignarRequerimiento, data: { IdNuevosIngresosEmpleado: fila.IdNuevosIngresosEmpleado }})}
                />
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody;


const GenericTooltip = ({id, text}) => (
  <>
    <FontAwesomeIcon
      data-tip data-for={id}
      size='lg'
      icon='info-circle'
      className="hand-pointer ml-3 blue-standard-color"
    />
    <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
      {text}
    </ReactTooltip>
  </>
)
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 450px; */
  grid-template-columns: 1fr 0.9fr;
  height: 100vh;

  button {
    background: #f49716;
    color: #ffffff;
    border: none;
    padding: 20px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 0.875rem;
    margin-top: 40px;
  }
  
  button:hover {
    background: #F5890F;
  }
   
  .login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .container-button-mob {
    display: none;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 992px) {
    display: block;
    .login {
      position: absolute;
    }
    .container-button {
      display: none;
    }
    .container-button-mob {
      display: flex;
    }
  }
`;

export const Intranet = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  background-image: url(${(props) => props.image});
  background-color: ${(props) => props.color};
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 992px) {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(
        to bottom,
        rgba(77, 135, 195, 0.7),
        rgba(0, 99, 161, 0.7)
      ),
      url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

import React from "react";
import PropTypes from 'prop-types';
import { DivInput } from "../atoms/DivInput";
import { INPUT_DIRECTION } from "../../constants/hardCodedConstants";

const InputText = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  sectionName,
  isRequired,
  isDisabled,
  colLabel=4,
  colInput=8,
  maxLength,
  direction = "row",
  placeholder = `Ingresar ${label}`,
}) => {
  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput} className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label d-flex ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color ${value ? "" : "highlight-input"}`}>
        <input
          type="text"
          placeholder={placeholder}
          className="form-control"
          name={name}
          onChange={onChange}
          value={value ?? ""}
          disabled={isDisabled}
          onBlur={onBlur}
          maxLength={maxLength}
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </DivInput>
  );
};

InputText.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  sectionName: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  direction: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputText;
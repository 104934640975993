import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";
import GenericUploadFileV2 from "../GenericUploadFile/GenericUploadFileV2";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { DivInput } from "../atoms/DivInput";

const InputUploadFile = ({
  label,
  uploadFile,
  setUploadFile,
  id,
  textButton = "Subir archivo",
  validExtensions,
  isUploading,
  setIsUploading,
  isIcon = false,
  icon,
  name,
  sectionName,
  colLabel=4,
  colInput=8,
  direction = "row",
  isRequired,
  isDisabled = false,
}) => {
  const { state } = useContext(AuthContext);

  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        {!isDisabled &&
          <>{uploadFile?.Path ? (
            <div>
              <a
                className="mx-4 hand-pointer blue-standard-color"
                onClick={() => {
                  downloadFile(
                    state.token,
                    uploadFile?.Path,
                    uploadFile?.OriginalFileName
                  );
                }}
              >
                {isIcon ? 
                  <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload} className="green-innova"/> :
                  <>
                    <FontAwesomeIcon icon="paperclip" transform="left-9" />
                    {uploadFile?.OriginalFileName}
                  </>
                }
              </a>
              <FontAwesomeIcon
                icon="trash"
                size="lg"
                title="Eliminar"
                className="hand-pointer blue-standard-color"
                onClick={() => setUploadFile({})}
              />
            </div>
          ) : (
            <div className="">
              <GenericUploadFileV2
                id={id}
                extensionesValidas={validExtensions}
                uploadFileToS3={setUploadFile}
                textButton={textButton}
                subiendoArchivoPublicState={isUploading}
                setSubiendoArchivoPublicState={setIsUploading}
                resetInitialFile={false}
                isIcon={isIcon}
                icon={icon}
              />
            </div>
          )}
          </>
        }
        {isDisabled && uploadFile?.Path && 
          <a
            className="mx-4 hand-pointer blue-standard-color"
            onClick={() => {
              downloadFile(
                state.token,
                uploadFile?.Path,
                uploadFile?.OriginalFileName
              );
            }}
          >
            {isIcon ? 
              <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload} className="green-innova"/> :
              <>
                <FontAwesomeIcon icon="paperclip" transform="left-9" />
                {uploadFile?.OriginalFileName}
              </>
            }
          </a>
        }
        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${name}-validation-message`} />
      </div>
    </DivInput>
  );
};

export default InputUploadFile;
import React, {useState} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';

import './estilos/css/App.css';
import {AuthProvider} from './hooks/context/authContext';
import AdminDash from './pages/router/AdminDash';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditarFichaDatosGlobal from './pages/gestionNuevosIngresos/ficha/EditarFichaDatosGlobal';

toast.configure()
library.add(fas);
const App = () => {
    const location = useLocation();

    return (
        <AuthProvider>
            <Switch>
                <Route component={() => <Login url={location?.state}/>} path='/login'/>
                <Route component={EditarFichaDatosGlobal} path='/datosNuevoIngreso'/>
                <PrivateRoute component={AdminDash} path='/'/>
            </Switch>
        </AuthProvider>
    );
};

export default App;

export const PAIS_ID_FICHA_CAMPO = 14;
export const CIUDAD_ID_FICHA_CAMPO = 19;
export const DEPARTAMENTO_ID_FICHA_CAMPO = 16;

export const ID_TODOS_OPTION_ON_DROPDOWN = 9999;

export const MENUS_FICHA_DATOS = {
    "datosPersonales": {
        "id": 1,
        "secciones": {
            "DatosIdentificacion": {"id": 1},
            "DatosDeNacimiento": {"id": 2},
            "InformacionDeResidencia": {"id": 3},
            "ContactoEmergencia": {"id": 5},
        }
    },
    "datosFamiliares": {
        "id": 2,
        "secciones": {
            "datosFamiliares": {"id": 6},
            "asignacionFamliar": {"id": 17},
        }
    },
    "datosEmpresa": {
        "id": 3,
        "secciones": {
            "datosEmpresa": {"id": 7}
        }
    },
    "academicData": {
        "id": 4,
        "secciones": {
            "tipoInstruccion": {"id": 12},
            "formacionProfesional": {"id": 13},
            "estudiosAdicionales": {"id": 14},
            "idiomas": {"id": 15},
        }
    },
    "laboralData": {
        "id": 5,
        "secciones": {
            "experienciaLaboral": {"id": 16}
        }
    },
    "archivos": {
        "id": 6,
        "secciones": {
            "contratos": {"id": 18},
            "actividadesPendientes": {"id": 19},
            "archivosVarios": {"id": 20},
            "documentosIngreso": {"id": 21},
        }
    },
};

export const MENU_DIRECTORIO =
{
    "directorioGeneral": {
        "id": 1,
    },
    "datosSede": {
        "id": 2,
    },
    "datosGrupo": {
        "id": 3,
    }
}


/*Reportes*/
export const REPORTE_FILE_NAME = "Reporte de datos generales.xlsx";
export const KEY_CAMPOS_CON_CHECK_POR_DEFECTO = ["NumeroDocumento", "Nombres", "ApellidoPaterno", "ApellidoMaterno"];
export const KEY_CAMPOS_CON_CHECK_DISABLED_POR_DEFECTO = ["NumeroDocumento", "Nombres", "ApellidoPaterno", "ApellidoMaterno"];

export const REPORTE_SEDE = {
    BACK_OFFICE: 1,
};

/*Menus*/
export const MI_TABLERO_ID_MENU = 1;
export const DIRECTORIO_ID_MENU = 2;
export const MIS_DATOS_ID_MENU = 3;
export const MI_EQUIPO_ID_MENU = 4;
export const REPORTES = 5;
export const MENU_ADMIN_PARENT = 6;
export const ADMIN_GESTION_FICHA_DATOS = 7;
export const ADMIN_SOLICITUD_DE_CAMBIOS = 8;
export const ADMIN_GESTION_PROCESO_CIERRE = 9;
export const ADMIN_GESTION_LIQUIDACIONES = 10;
export const ADMIN_CESES = 11;
export const ADMIN_GESTION_TABLERO = 12;
export const ADMIN_TABLAS_GENERALES = 13;
export const ADMIN_NUEVOS_INGRESOS = 14;
export const ADMIN_GESTION_ROLES = 17;
export const ADMIN_GESTION_USUARIOS = 18;
export const ADMIN_GESTION_ENCUESTA_SALUD = 23;
export const ADMIN_GESTION_CIERRE_ANIO = 24;
export const ADMIN_GESTION_RESERVAS = 25;
export const ADMIN_GESTION_VACUNACION = 28;
export const ADMIN_GESTION_CAPACITACION = 32;
export const ADMIN_GESTION_PRUEBAS_COVID = 33;
export const ADMIN_GESTION_FICHA_SINTOMATOLOGICA = 34;
export const ADMIN_GESTION_SEGUIMIENTO_CASOS_COVID = 36;
export const ADMIN_GESTION_POLITICAS_REGLAMENTOS = 39;
export const ADMIN_GESTION_DIRECTORIO = 42;
export const ADMIN_GESTION_INVITACION_ONBOARDING = 43;
export const ADMIN_GESTION_VACACIONES = 44;
export const ADMIN_GESTION_SCHEDULE_RUNNER = 46;
export const ADMIN_GESTION_PLANTILLAS = 47;
export const ADMIN_GESTION_NUEVOS_INGRESOS = 48;
export const ADMIN_GESTION_DESCUENTO_PENSION = 49;
export const ADMIN_SOLICITUD_TAILOY = 55;
export const ADMIN_ASIGNACION_FAMILIAR = 57;
export const ADMIN_GESTION_REQUERIMIENTOS = 58;
export const ADMIN_GESTION_TRASLADOS = 59;
export const ADMIN_GESTION_CUPONERA = 61;
export const ADMIN_GESTION_CHATBOT = 65;
export const ADMIN_GESTION_POPUP = 66;

export const MENU_MIS_SOLICITUDES = {
    "menuId": 15,
    "childMenuIds": {
        "renuncias": 16,
        "vacunas": 19,
        "misActividades": 21,
        "descargarConstanciaDeTrabajo": 20,
        "misReservas": 26,
        "cierreDeAnio": 27,
        "seguimientoColaboradores": 30,
        "misCapacitaciones": 37,
        "politicasYReglamentos": 38,
        "siteDirectores": 40,
        "MisVacaciones": 45,
        "solicitarDescuentoPension": 50,
        "miFichamedica": 51,
        "MisValesTaiLoy": 56,
        "MisTraslados": 60,
        "MisCuponesTiempoLibre": 62,
    }
};

export const MENU_COVID = {
    "menuId": 41,
    "childMenuIds": {
        "retornoClases": 31,
        "fichaSintomatologica": 35,
    }
}

/*Secciones Reportes*/
export const SECCIONES_REPORTES = {
    "ReporteDeDatosGenerales": {"id": 401},
    "ReporteDeClimaLaboral": {"id": 402},
    "ReporteDeRenuncias": {"id": 410},
    "ReporteCoordinarDevolucion": {"id": 424},
    "ReporteProcesoDeCierre": {"id": 425},
    "ReporteDeCumpleanios": {"id": 426},
    "ReporteNuevosIngresos": {"id": 444},
    "ReporteVacaciones": {"id": 446},
    "ReporteAsignacionFamiliarRegistro": {"id": 453},
    "ReporteAsignacionFamiliarDetallado": {"id": 454},
    "ReporteCierreAnioRenovacion": {"id": 455},
    "ReporteVacacionesDetallado": {"id": 457},
    "ReporteColaboradoresActivos": {"id": 458},
    "ReporteColaboradoresCesados": {"id": 459},
    "ReporteVacacionesPorPeriodoPlanilla": {"id": 466},
    "ReporteSeguimientoNuevosIngresos": {"id": 468},
    "ReporteyTablasGoldenbelt": {"id": 471},
    "ReporteDatosEmpleadoTxtOfisis": {"id": 480},
    "ReporteDatosHijos": {"id": 481},
    "ReporteBBDDColaboradoresActivos": {"id": 492},
    "ReporteRankmiColaboradoresActivos": {"id": 505},
    "ReporteVacacionesMatriciales": {"id": 518},
}

/* github images */
export const MI_TABLERO_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/11_ESCUDO_INNOVA.svg";
export const DIRECTORIO_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/1_DIRECTORIO.svg";
export const MIS_DATOS_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/2_MIS_DATOS.svg";
export const MI_EQUIPO_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/3_EQUIPO.svg";
export const REPORTES_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/4_REPORTES.svg";
export const ADMINISTRADOR_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/5_ADMINISTRADOR.svg";
export const MIS_OPCIONES_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/6_MIS_OPCIONES.svg";
export const COVID_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/7_COVID.svg";
export const GDH_TE_ESCUCHA_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/8_GDH_TE_ESCUCHA-21.svg";
export const OTRAS_APLICACIONES_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/10_OTRAS_APLICACIONES.svg";

export const DISEÑO_X_COLORES_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/DISEÑO_X_DE_COLORES.svg";
export const VACACIONES_Y_LICENCIAS_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/20_VACACIONES_Y_LICENCIAS.svg";
export const CUPCAKE_ICON = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/13_CUPCAKE.svg";
export const ETIQUETA_NEW_CARDS = "https://intranetinnova.github.io/IntranetFileRepository/Images/Tablero/12_NUEVO.svg"

/* Header Routes */

const HEADER_ADMIN_ROUTES_LABEL_MAP = [
    {route: '/gestionFichaDatos', label: 'Gestión ficha datos', idAcceso: ADMIN_GESTION_FICHA_DATOS},
    {route: '/adminSolicitudCambios', label: 'Gestión de Solicitudes de cambio', idAcceso: ADMIN_SOLICITUD_DE_CAMBIOS},
    {route: '/adminRenuncias', label: 'Gestión de Renuncias', idAcceso: ADMIN_CESES},
    {route: '/adminTablasGenerales', label: 'Tablas Generales', idAcceso: ADMIN_TABLAS_GENERALES},
    {route: '/nuevosIngresos', label: 'Gestión Actividades Pendientes', idAcceso: ADMIN_NUEVOS_INGRESOS},
    {route: '/adminGestionRoles', label: 'Gestión de Roles', idAcceso: ADMIN_GESTION_ROLES},
    {route: '/adminGestionUsuarios', label: 'Gestión de Usuarios', idAcceso: ADMIN_GESTION_USUARIOS},
    {route: '/adminGestionCapacitacion', label: 'Gestión de capacitaciones', idAcceso: ADMIN_GESTION_CAPACITACION},
    {route: '/adminGestionProgramacionPruebasCovid', label: 'Gestión de programación de pruebas Covid', idAcceso: ADMIN_GESTION_PRUEBAS_COVID},
    {route: '/adminGestionEncuestaSalud', label: 'Gestión Ficha Médica 2023', idAcceso: ADMIN_GESTION_ENCUESTA_SALUD},
    {route: '/adminGestionCierreAnio', label: 'Gestión de Contratos', idAcceso: ADMIN_GESTION_CIERRE_ANIO},
    {route: '/gestionReservas', label: 'Gestión Reservas', idAcceso: ADMIN_GESTION_RESERVAS},
    {route: '/gestionPoliticasYReservas', label: 'Gestión de políticas y reglamentos', idAcceso: ADMIN_GESTION_POLITICAS_REGLAMENTOS},
    {route: '/gestionVacunacion', label: 'Gestión de vacunación', idAcceso: ADMIN_GESTION_VACUNACION},
    {route: '/procesosDeCierre', label: 'Configuración Proceso Cierre', idAcceso: ADMIN_GESTION_PROCESO_CIERRE},
    {route: '/liquidaciones', label: 'Gestión Liquidaciones', idAcceso: ADMIN_GESTION_LIQUIDACIONES},
    {route: '/adminGestionDirectorio', label: 'Gestión de Directorio', idAcceso: ADMIN_GESTION_DIRECTORIO},
    {route: '/registroInvitacionOnboarding', label: 'Gestión Calendario Onboarding', idAcceso: ADMIN_GESTION_INVITACION_ONBOARDING},
    {route: '/adminGestionVacaciones', label: 'Gestión de Vacaciones', idAcceso: ADMIN_GESTION_VACACIONES},
    {route: '/adminSchedule', label: 'Gestion Monitor Runner', idAcceso: ADMIN_GESTION_SCHEDULE_RUNNER},
    {route: '/adminPlantillas', label: 'Gestión de Plantillas', idAcceso: ADMIN_GESTION_PLANTILLAS},
    {route: '/adminNuevosIngresos', label: 'Gestión Nuevos Ingresos', idAcceso: ADMIN_GESTION_NUEVOS_INGRESOS},
    {route: '/adminSolicitudValeDescuento', label: 'Gestión Solicitudes Vale Tai Loy', idAcceso: ADMIN_SOLICITUD_TAILOY},
    {route: '/adminGestionRequerimientos', label: 'Gestión de Requerimientos', idAcceso: ADMIN_GESTION_REQUERIMIENTOS},
    // {route: '/adminTraslados', label: 'Gestión de Traslados', idAcceso: ADMIN_GESTION_TRASLADOS},
    {route: '/gestionSolicitudDescuentoPension', label: 'Gestion Solicitud Descuento Pensión', idAcceso: ADMIN_GESTION_DESCUENTO_PENSION},
    {route: '/adminSolicitudesAsignacionFamiliar', label: 'Gestion Solicitudes Asignación Familiar', idAcceso: ADMIN_ASIGNACION_FAMILIAR},
    {route: '/gestionFichaSintomatologica', label: 'Seguimiento de ficha sintomatológica', idAcceso: ADMIN_GESTION_FICHA_SINTOMATOLOGICA},
    {route: '/seguimientoCasosCovid', label: 'Seguimiento de casos covid', idAcceso: ADMIN_GESTION_SEGUIMIENTO_CASOS_COVID},
    {route: '/adminCuponeraTiempoLibre', label: 'Gestión Solicitudes de Cupones de Tiempo Libre', idAcceso: ADMIN_GESTION_CUPONERA},
    {route: '/adminChatbotFaq', label: 'Gestión Chatbot', idAcceso: ADMIN_GESTION_CHATBOT},
    {route: '/adminPopup', label: 'Gestión Popup', idAcceso: ADMIN_GESTION_POPUP},
]

export const MENU_HEADER_ITEMS_MAP = [
    {route: '/directorio', title: 'Directorio', icon: DIRECTORIO_ICON, idAcceso: DIRECTORIO_ID_MENU},
    {route: '/fichadedatos', title: 'Mis Datos', icon: MIS_DATOS_ICON, idAcceso: MIS_DATOS_ID_MENU},
    {route: '/miequipo', title: 'Mi Equipo', icon: MI_EQUIPO_ICON, idAcceso: MI_EQUIPO_ID_MENU},
    {route: '/reportes', title: 'Reportes', icon: REPORTES_ICON, idAcceso: REPORTES},
    {isDropdown: true, title: 'Administrador', routeMap: HEADER_ADMIN_ROUTES_LABEL_MAP, icon: ADMINISTRADOR_ICON, idAcceso: MENU_ADMIN_PARENT},
]

/*File*/
export const MAX_FILE_SIZE = 8388608;//8MB
export const EXTENSIONES_VALIDAS_DE_ARCHIVOS = ["jpg", "jpeg", "png", "pdf"];

/*Router*/
export const FROM_MI_EQUIPO = "fromMiEquipo";
export const FROM_GESTION_FICHA = "fromGestionFicha";

/*Fields*/
export const LIST_FIELD_TYPE = "List";

/*SECION EMPRESA*/
export const TIPO_DE_CONTRATO_INDEFINIDO_ID = 214;

export const ESTADOS_RENUCIA = {
    "aprobado": {"id": 14},
    "rechazado": {"id": 15}
}

/*ESTADO DE ACTUALIZACION DE FICHA */
export const ESTADOS_ACTUALIZACION_FICHA = {
    "pendiente": {"id": 10},
    "observado": {"id": 13},
    "aprobado": {"id": 14}
};

export const TIPOS_ACCESO = {
    "campos": {"id": 3}
};

export const TIPOS_VINCULOS = {
    "Concubino": {"id": 154},
    "Conyuge": {"id": 153},
    "Hijo": {"id": 152}
};

export const ESTADOS_ROLES = {
    "Activo": {"id": 1},
    "Inactivo": {"id": 2},
};

export const ESTADOS_VACUNACION = {
    "Completado": {"id": 9},
    "SinVacuna": {"id": 20},
    "PrimeraDosis": {"id": 21}
};

export const FLUJOS = {
    "datosDeRenuncia": {id: 0},
    "nuevosIngresos": {id: 0},
    "validaciónDeDatosDeNacimiento": {id: 1},
    "autorizacionDatosPersonales": {id: 4},
    "firmaActaTRegistro": {id: 33}
}

export const ACTIVIDADES_PENDIENTES_MODALES = {
    "Modal_Validacion_Ficha": {"id": "Modal_Validacion_Ficha"},
    "Modal_Verificar_ER_Pendiente": {"id": "Modal_Verificar_ER_Pendiente"},
    "Modal_Generar_Liquidacion": {"id": "Modal_Generar_Liquidacion"},
    "Modal_Ampliar_Liquidacion": {"id": "Modal_Ampliar_Liquidacion"},
    "Modal_Confirmar_Prestamo": {"id": "Modal_Confirmar_Prestamo"},
    "Modal_Coordinar_Devolucion": {"id": "Modal_Coordinar_Devolucion"},
    "Modal_Confirmar_Estado_Recepcion_Equipos": {"id": "Modal_Confirmar_Estado_Recepcion_Equipos"},
    "Reprocesar_Actividad": {"id": "Reprocesar_Actividad"},
    "Modal_Firmar_Carta_Autorizacion": {"id": "Modal_Firmar_Carta_Autorizacion"},
    "Modal_Validar_Firma_Carta_Autorizacion": {"id": "Modal_Validar_Firma_Carta_Autorizacion"},
    "Modal_Validar_Plantilla_Compromiso_Vacunacion": {"id": "Modal_Validar_Plantilla_Compromiso_Vacunacion"},
    "Modal_Firmar_Carta_Compromiso_Vacunacion": {"id": "Modal_Firmar_Carta_Compromiso_Vacunacion"},
    "Modal_Firma_Compromiso_Lectura_Politicas": {"id": "Modal_Firma_Compromiso_Lectura_Politicas" },
    "Modal_Validar_Carta_Compromiso_Vacunacion_Firmado": {"id": "Modal_Validar_Carta_Compromiso_Vacunacion_Firmado"},
    "Modal_Firmar_Carta_Incremento": {"id": "Modal_Firmar_Carta_Incremento"},
    "Modal_Carta_Compromiso_Vacunacion": {"id": "Modal_Carta_Compromiso_Vacunacion"},
    "Modal_Firmar_Carta_Aprobado_LineaCarrea": {"id": "Modal_Firmar_Carta_Aprobado_LineaCarrea"},
    "Modal_Firmar_carta_bono": {"id": "Modal_Firmar_carta_bono"},
    "Modal_Firmar_Documento_Teletrabajo": {"id": "Modal_Firmar_Documento_Teletrabajo"},
    "Modal_Firmar_CompraVacaciones": {"id": "Modal_Firmar_CompraVacaciones"},
    "Modal_Firmar_CartaCambioPuesto": {"id": "Modal_Firmar_CartaCambioPuesto"},
    "Modal_Firmar_Carta_Incremento_2023": {"id": "Modal_Firmar_Carta_Incremento_2023"},
    "Modal_Firmar_BonoTraslado" : {"id" : "Modal_Firmar_BonoTraslado"},
    "Modal_Firmar_CartaIncrementoBO2023" : {"id" : "Modal_Firmar_CartaIncrementoBO2023"},
    "Modal_EncuestaTeletrabajo_Link_Capacitacion" : {"id" : "Modal_EncuestaTeletrabajo_Link_Capacitacion"},
    "Modal_EncuestaTeletrabajo_Instructivo" : {"id" : "Modal_EncuestaTeletrabajo_Instructivo"},
    "Modal_EncuestaTeletrabajo_Encuesta" : {"id" : "Modal_EncuestaTeletrabajo_Encuesta"},
    "Modal_Test_Induccion": {"id": "Modal_Test"},
    "Modal_Encuesta_Induccion": {"id": "Modal_Encuesta"},
    "Modal_CreaCorreo": {"id": "Modal_CreaCorreo"},
    "Modal_Archivos": {"id": "Modal_Archivos"},
    "Modal_ConfirmarAsistencia": {"id": "Modal_ConfirmarAsistencia"},
    "Modal_Confirmacion_jira": {"id": "Modal_Confirmacion_jira"},
    "Modal_Confirmacion_dm": {"id": "Modal_Confirmacion_dm"},
    "Modal_ConfirmacionFechaIngreso": {"id": "Modal_ConfirmacionFechaIngreso"},
    "Modal_Firmar_ConvenioCambioFunciones": {"id": "Modal_Firmar_ConvenioCambioFunciones"},
    "Modal_Firmar_carta_bono_v2": {"id": "Modal_Firmar_carta_bono_v2"},
    "Modal_Firmar_carta_bono_v3": {"id": "Modal_Firmar_carta_bono_v3"},
    "Modal_Firmar_LineaCarrera2023": {"id": "Modal_Firmar_LineaCarrera2023"},
    "Modal_ni_documentoObservado": {"id": "Modal_ni_documentoObservado"},
    "Modal_ni_documentosPendientes": {"id": "Modal_ni_completar_documentos"},
    "Modal_ni_actualizar_correo": {"id": "Modal_ni_actualizar_correo"},
    "Modal_indicar_amonetaciones": {"id": "Modal_indicar_amonetaciones"},
    "Modal_Firmar_Carta_Incremento_2024" : {"id" : "Modal_Firmar_Carta_Incremento_2024"},
    "Modal_Convenio_Mutuo_Disenso" : {"id" : "Modal_Convenio_Mutuo_Disenso"},
    "Modal_Firmar_Carta_Bono_Tot" : {"id" : "Modal_Firmar_Carta_Bono_Tot"},
    "Modal_Firma_Documento_EMO" : {"id" : "Modal_Firma_Documento_EMO"},
    "Modal_Firmar_ConvenioPuesto" : {"id" : "Modal_Firmar_ConvenioPuesto"},
    "Modal_TRegistro_subirDocumentoFirmado" : {"id" : "Modal_TRegistro_subirDocumentoFirmado"},
    "Modal_Firmar_carta_remcompesa_Sedev1" : {"id" : "Modal_Firmar_carta_remcompesa_Sedev1"},
    "Modal_Firmar_carta_remcompesa_BOv1" : {"id" : "Modal_Firmar_carta_remcompesa_BOv1"},
    "Modal_Firmar_Resultado_evaluacion2024" : {"id" : "Modal_Firmar_Resultado_evaluacion2024"},
    "Modal_Firmar_Documento_Prorroga": {"id": "Modal_Firmar_Documento_Prorroga"},
};

export const GENERIC_SUCCESS_TOAST_MESSAGE = "Operación exitosa";

export const CIERRE_DE_ANIO_ESTADOS_PROCESO = {
    "observado": {"id": 13}
}
export const SEDES = {
    BACK_OFFICE: 1
}

export const PAISES = {
    PERU: 155
}

export const TIPOS_TRABAJADOR = {
    PRACTICANTE_PRE: 23607,
    PRACTICANTE_PRO: 23608,
}

export const TIPOS_EMPLEADO = {
    PRACTICANTE: 2153
}

export const BANCOS = {
    INTERBANK: 24,
    NOAPLICA: 27
}

export const IDFIELDS ={
    IDFIELDBANCOSUELDO: 62,
    IDFIELDBANCOCTS: 66
}

export const SISTEMAS_PENSIONARIOS = {
    SIN_REGISTRO_PENSIONARIO: 97
}

export const MONEDAS = {
    SOLES: 587
}

export const TIPOS_DOCUMENTO_IDENTIDAD = {
    CEX: 69
}

export const CARGOS = {
    ASISTENTE_EDUCACION: 648,
    DIRECTOR_COLEGIO: 740,
    PROFESOR: 847,
    PRACTICANTE: 843,
    PRACTICANTE_COMERCIAL: 844,
    PRACTICANTE_PSICOLOGIA: 1038,
    PRACTICANTE_ATENCIÓN_CLIENTE: 1071,
    PRACTICANTE_EXPERIENCIA_COLABORADOR: 1642,
    PRACTICANTE_SELECCIÓN: 1651,
    PRACTICANTE_COMUNICACIONES: 1662,
    PRACTICANTE_SELECCION: 2890,
    PRACTICANTE_CULTURA : 2897,
    PRACTICANTE_PROCESOS: 23585,
    PRACTICANTE_MARCA_Y_CONTENIDO: 23611,
}

export const GRADOS_DESARROLLO = {
    INNOVA: 84
}

export const RESERVA_TIPO_UBICACION_ENTITY = {
    INDIVIDUAL: 1,
    SALA: 2
}

export const FORMAS_INGRESO = {
    BANCO_CURRICULUM: 570,
}

export const TIPOS_CONTRATO = {
    "Indefinido": {"IdEntity": 1},
    PART_TIME: [1649, 185],
    NECESIDAD_MERCADO: 187,
    SUPLENCIA: 190,
    OBRA_SERVICIO: 192,
    INCREMENTO_ACTIVIDAD: 213
}


export const RESERVA_MOTIVOS_ENTITY = {
    MOTIVO_PERSONAL: 8
}

export const ESTADOS = {
    ACTIVO: 1,
    CREADO: 3,
    VALIDADO: 8,
    COMPLETADA: 9,
    PENDIENTE: 10,
    OBSERVADO: 13,
    EN_PROCESO: 16,
    SI: 27,
    NO: 28,
    REVISION: 33,
    DESISTE: 45,
    NUEVO: 47,
}

export const CONDICIONES_CIERRE_DE_ANIO = {
    "Renueva": {"id": 1},
    "NoRenueva": {"id": 2},
    "Vacaciones": {"id": 3},
    "Cese/Ingreso": {"id": 4}
}

export const ESTADOS_CONDICION_RIESGO = [
    { value: true, label: "Sí" },
    { value: false, label: "No" }
];

export const ESTADOS_YES_NO = [
    { value: true, label: "Sí" },
    { value: false, label: "No" }
];

export const ESTADO_RESULTADO = {
    value: 31,
    label: "Conforme"
};

export const ESTADO_FICHA_SALUD = {
    value: 8,
    label: "Validado"
};

export const ESTADO_RESULTADO_COVID = {
    POSITIVO: 40,
    NEGATIVO: 41
}

export const ROLES = {
    "SuperAdmin": {'id': 1},
    "Compensaciones": {'id': 2},
    "Sistemas": {'id': 3},
    "Colaborador": {'id': 4},
    "LiderEquipo": {'id': 5},
    "Cultura": {'id': 6},
    "Seleccion": {'id': 7},
    "Proyectos": {'id': 8},
    "Reportes": {'id': 9},
    "Compras": {'id': 10},
    "Bienestar": {'id': 11},
    "Desarrollo": {'id': 12},
    "EquipoDirectivo": {'id': 13},
    "FichaConsulta": {'id': 14},
    "Dupla": {'id': 17},
    SoporteSistemas: {id: 32},
    CoodinadorGDH: {id: 36},
    LiderMatricial: {'id': 51},
}

export const ESTADOS_ETAPAS_CIERRE_DE_ANIO = {
    CREADO: 3,
    EN_PROCESO: 16,
    FINALIZADO: 19
}

export const ETAPAS_CIERRE_DE_ANIO = {
    CREADO: "Creado",
    PENDIENTE: "Pendiente",
    VALIDADO: "Validado",
    COMPLETADA: "Completada",
    FINALIZADO: "Finalizado",
    OBSERVADO: "Observado"
}

export const ALL_ESTADOS = {
    "Actualizado" : 6,
    "Cerrado":7
}

export const TIPO_CESE_RENUNCIA = {
    id: 924
}

export const TIPO_CESE_IDS_MOTIVO = [1,5,6];

export const OPERADORES_COMPARACION = [
    {value: 'mayor', label: '>'},
    {value: 'menor', label: '<'},
    {value: 'menorIgual', label: '<='},
    {value: 'mayorIgual', label: '>='},
]

export const TIPO_SOLICITUD_ASIGNACION_FAMILIAR = {
    ValidarFamiliar: 1,
    ValidarHijoMenor18YModifAF: 2,
    SolAFMayor18años: 3,
    ValidarHijoMayor18RetirarAF: 4,
    Null: 0
}

//#region Admin Liquidaciones
export const ID_TYPES_LIQUIDACIONES = {
    Sedes: "IdsSedes",
    Cargos: "IdsCargos",
    ProcesosCierre: "IdsProcesosCierre",
    Estados: "IdsEstados",
    Tiposcontrato: "IdsTiposcontrato"
};

export const HEAD_TABLE_LIQUIDACIONES = {
    CodigoTrabajo: {id: "IdCodigoTrabajo", title: "Cod. Trab."},
    NombreCompleto: {id: "NombreCompleto", title: "Nombre Completo"},
    Sedes: {id: "IdsSedes", title: "Sede"},
    Cargos: {id: "IdsCargos", title: "Cargo"},
    ProcesosCierre: {id: "IdsProcesosCierre", title: "Proceso Cierre"},
    Cese: {id: "IdsCese", title: "Id Cese"},
    UltimoDia: {id: "UltimoDia", title: "Último día de labores"},
    Estados: {id: "IdsEstados", title: "Estado Envío"},
    Acciones: {id: "Acciones", title: "Acciones"}
}
//#endregion

//#region Admin Runner Schedule
export const KEYS_SCHEDULE_RUNNER = {
    Nombre: "ClassName", 
    Frecuencia: "Frecuency",
    Dia: "Day",
    Hora: "Hour",
    DiaSemana: "DayOfweek"
};

export const TYPE_FRECUENCY_SCHEDULE_RUNNER = {
    EveryDay: [
        {label: "Hora", key: KEYS_SCHEDULE_RUNNER.Hora}
    ], 
    Weekly: [
        {label: "Hora", key: KEYS_SCHEDULE_RUNNER.Hora},
        {label: "Dia de la semana", key: KEYS_SCHEDULE_RUNNER.DiaSemana}
    ],
    Monthly: [
        {label: "Hora", key: KEYS_SCHEDULE_RUNNER.Hora},
        {label: "Dia", key: KEYS_SCHEDULE_RUNNER.Dia}
    ]};

//#endregion

//#region Nuevos Ingresos

// Create initial promises ids
export const ID_TYPES_NUEVOS_INGRESOS = {
    FormaIngreso: "IdFormaIngreso",
    TipoDocumento: "IdTipoDocumento",
    Genero: "IdGenero",
    TipoInstruccion: "IdTipoInstruccion",
    Cargo: "IdCargo",
    Sede: "IdSede",
    SedeSecundaria: "IdSedeSecundaria",
    Division: "IdDivision",
    EspecialidadSeleccion: "IdEspecialidadSeleccion",
    GradoDesarrollo: "IdGradoDesarrollo",
    TipoEmpleado: "IdTipoEmpleado",
    TipoContrato: "IdTipoContrato",
    ListaDocumentos: "IdsListasDocumentos",
    ResponsableProceso: "IdResponsableProceso",
    TiposLicencia: "IdTipoLicencia",
    PeriodoPrueba: "IdPeriodoPrueba",
    CondicionAcademica: "IdCondicionAcademica",
    TipoTrabajador: "IdTipoTrabajador",
    FuenteReclutamiento: "IdFuenteReclutamiento"
}

// Create cards
export const KEYS_CARD_NUEVOS_INGRESOS = {
    idDatosIdentificacion: "datosIdentificacion",
    idDatosNacimiento: "datosNacimiento",
    idDatosResidencia: "datosResidencia",
    idFormacionAcademica: "datosFormacionAcademica",
    idDatosEmpresa: "datosEmpresa",
    idInformacionEconomica: "informacionEconomica",
    idDatosNuevoIngreso: "datosNuevoIngreso",
    idDatosFamiliares: "datosFamiliares"
}

// Create Validations
export const KEYS_FORM_NUEVOS_INGRESOS = [
    // 1. Datos Identificación
    "IdTipoDocumento",
    "NumeroDocumento",
    "NumeroPasaporte",
    "Nombres",
    "ApellidoPaterno",
    "ApellidoMaterno",
    "IdGenero",
    "Correo",
    "Celular",
    // 2. Datos Nacimiento
    // "FechaNacimiento",
    // 3. Formación Académica
    // "IdTipoInstruccion",
    // 4. Datos Empresa
    "FechaIngresoEmpresa",
    "FechaIngresoReal",
    "IdCargo",
    "IdSede",
    "IdSedeSecundaria",
    "IdDivision",
    "IdGerencia",
    "IdArea",
    "IdSeccion",
    "IdEspecialidadSeleccion",
    "IdGradoDesarrollo",
    "Remuneracion",
    "IdTipoTrabajador",
    "IdFuenteReclutamiento",
    "IdTipoEmpleado",
    "IdTipoContrato",
    "IdRegimenLaboral",
    "FechaVencimientoContrato",
    "IdEmpleadoLider",
    "CantDiasSemana",
    "NombreProyectoServicio",
    "IdColaboradorSuplencia",
    "IdTipoLicencia",
    "DiasLicencia",
    "IdPeriodoPrueba",
    "Profesion",
    "Especialidad",
    "IdCondicionAcademica",
    // 5. Datos Nuevo Ingreso
    "IdsListasDocumentos",
    "IdListaDocumentos",
    "EsReingreso",
    "IdResponsableProceso",
    "FechaLimiteDocumentos",
    "TieneBonoMovilidad",
    "MontoBonoMovilidad",
    "TieneBonoDesatajo",
    "MontoBonoDesatajo",
    "TieneBonoReferido",
    "MontoBonoReferido",
    "IdEmpleadoReferido",
]

// Edit Datos Identificacion
export const KEYS_DATOS_IDENTIFICACION_NI = ["IdTipoDocumento", "NumeroDocumento", "NumeroPasaporte", "Nombres", "ApellidoPaterno", "ApellidoMaterno", "IdGenero", "IdEstadoCivil", "Correo", "Telefono", "Celular"]

// Edit Datos Nacimiento
export const KEYS_DATOS_NACIMIENTO_NI = ["FechaNacimientoValue", "FechaNacimiento", "IdDepartamento", "IdProvincia", "IdDistrito", "IdPaisNacimiento"]

// Edit Datos Residencia
export const KEYS_DATOS_RESIDENCIA_NI = ["IdTipoVivienda", "IdTipoVia", "NombreVia", "NumeroCasa", "Interior", "IdTipoZona", "NombreZona", "IdPaisREsidencia", "IdDepartamento", "IdProvincia", "IdDistrito"]

// Edit Formacion Académica
export const KEYS_FORMACION_ACADEMICA_NI = ["IdTipoInstruccion", "NombreInstitucion", "IdPaisInstitucion", "IdTipoInstitucion", "IdSectorInstitucion", "NombreCarrera", "IdEspecialidad", "IdNivelEstudio", "IdEstadoFormacion", "IdGradoObtenido", "FechaInicio", "FechaFin", "IsSetFinalizado", "IsSetDeclaracionJurada", "IsGraduado", "IsNoCulminado", "SustentoFile", "IdEmpleadoFormacionProfesional"]

// Edit Datos Empresa
export const KEYS_DATOS_EMPRESA_NI = ["IdContrato", "FechaIngresoEmpresaValue", "FechaIngresoEmpresa", "FechaIngresoRealValue", "FechaIngresoReal", "IdCargo", "IdSede", "IdSedeSecundaria", "IdDivision", "IdGerencia", "IdArea", "IdSeccion", "Remuneracion", "IdTipoTrabajador", "IdFuenteReclutamiento", "IdTipoEmpleado", "IdTipoContrato", "IdRegimenLaboral", "FechaInicioContratoValue", "FechaInicioContrato", "FechaVencimientoContratoValue", "FechaVencimientoContrato", "IdFormaIngreso", "CorreoCorporativo", "IdEmpleadoLider", "IdEspecialidadSeleccion", "IdGradoDesarrollo", "CantDiasSemana", "NombreProyectoServicio", 'IdColaboradorSuplencia', "IdTipoLicencia", "DiasLicencia", "IdPeriodoPrueba", "Profesion", "Especialidad", "IdCondicionAcademica"]

// Edit Información Económica
export const KEYS_INFORMACION_ECONOMICA_NI = ["IdFondoPensiones", "Cuspp", "IdBancoSueldo", "NumeroCuentaSueldo", "Sustento", "IdBancoCts", "CuentaCts", "IdMonedaCts", "AsignacionFamiliar", "IngresosOtroEmpleador", "AceptaDj", "TieneQuintaCategoria", "TieneHijos"]

// Edit Datos Nuevo Ingreso
export const KEYS_DATOS_NUEVO_INGRESO_NI = ["IdFlujoOnboarding", "EsReingreso", "IdResponsableProceso", "FechaLimiteDocumentosValue", "FechaLimiteDocumentos", "TieneBonoMovilidad", "TieneBonoDesatajo", "TieneBonoReferido", "MontoBonoMovilidad", "MontoBonoDesatajo", "MontoBonoReferido", "IdEmpleadoReferido"]

// Edit Datos Familiares Nuevo Ingreso
export const KEYS_DATOS_FAMILIARES = ["Familiares", "DeclaraInformacionFamiliar", "AceptaDeclaracionJurada"];

//#endregion

//#region keys de todas las secciones de nuevo ingreso

export const ALL_SECTION_KEYS_NUEVOS_INGRESOS = [
    {SECTION: "EditarDatosIdentificacionFichaNuevosIngresos", KEYS: KEYS_DATOS_IDENTIFICACION_NI},
    {SECTION: "EditarDatosNacimientoFichaNuevosIngresos", KEYS: KEYS_DATOS_NACIMIENTO_NI},
    {SECTION: "EditarDatosResidenciaFichaNuevosIngresos", KEYS: KEYS_DATOS_RESIDENCIA_NI},
    {SECTION: "EditarDatosFormacionAcademicaFichaNuevosIngresos", KEYS: KEYS_FORMACION_ACADEMICA_NI},
    {SECTION: "EditarDatosEmpresaFichaNuevosIngresos", KEYS: KEYS_DATOS_EMPRESA_NI},
    {SECTION: "EditarDatosInformacionEconomicaFichaNuevosIngresos", KEYS: KEYS_INFORMACION_ECONOMICA_NI},
    {SECTION: "EditarDatosNuevoIngresoFichaNuevosIngresos", KEYS: KEYS_DATOS_NUEVO_INGRESO_NI},
    {SECTION: "EditarDatosFamiliaresNuevosIngresos", KEYS: KEYS_DATOS_FAMILIARES},
    {SECTION: "EditarListasDocumentos", KEYS: ["Files"]},
]

export const NUEVOS_INGRESOS_FICHA_STEPS = {
    stepOne: {id: 1, name: "Datos de Identificación"},
    stepTwo: {id: 2, name: "Formación Académica"},
    stepThree: {id: 3, name: "Datos de Nacimiento"},
    stepFour: {id: 4, name: "Dirección Actual"},
    stepFive: {id: 5, name: "Información Económica"},
    stepSix: {id: 6, name: "Datos Familiares"},
    stepSeven: {id: 7, name: "Lista de Documentos"},
}

//#endregion

export const INPUT_DIRECTION = {
    row: "input-form-row",
    column: "input-form-column"
}

export const DIACRITIC_MAP = {
    "à": "a",
    "á": "a",
    "è": "e",
    "é": "e",
    "ì": "i",
    "í": "i",
    "ò": "o",
    "ó": "o",
    "ù": "u",
    "ú": "u",
}

export const NUMBER_TO_MONTHS = {
    "01": "ene",
    "02": "feb",
    "03": "mar",
    "04": "abr",
    "05": "may",
    "06": "jun",
    "07": "jul",
    "08": "ago",
    "09": "set",
    "10": "oct",
    "11": "nov",
    "12": "dic",
}

export const WEEK_DAYS = Object.freeze({
    Monday: {number: 1, smallName: "Lu", longName: "Lunes"},
    Tuesday: {number: 2, smallName: "Ma", longName: "Martes"},
    Wednesday: {number: 3, smallName: "Mi", longName: "Miércoles"},
    Thursday: {number: 4, smallName: "Ju", longName: "Jueves"},
    Friday: {number: 5, smallName: "Vi", longName: "Viernes"},
    Saturday: {number: 6, smallName: "Sá", longName: "Sábado"},
    Sunday: {number: 7, smallName: "Do", longName: "Domingo"},
})

export const TIPOS_CIERRE_REQUERIMIENTO = {
    NuevoIngreso: 1,
    PromocionTraslado: 2,
}

export const CHATBOT_FAQ_TYPES = {
    "pregunta": 1,
    "respuesta": 2,
}

export const TIPOS_LISTAR_VACACIONES = {
    Matricial: {
        'value' : "Matricial",
        'text' : "Cargos matriciales"
    },

    NoMatricial: {
        'value' : "NoMatricial",
        'text': "Reportes directos"
    },


}


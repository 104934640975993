import React, {useEffect, useState, useContext} from 'react';

import { Route, Switch } from 'react-router-dom';
import Sidebar from '../../components/atoms/Sidebar';
import Header from '../../components/header/Header';
import Directorio from '../directorio/Directorio';
import FichaDatos from '../fichaMisDatos/FichaDatos';
import MiEquipo from "../miEquipo/MiEquipo";
import Reportes from "../reportes/Reportes";
import GestionFichaDatos from "../gestionFichaDatos/GestionFichaDatos";
import AdminSolicitudCambios from "../adminSolicitudCambios/AdminSolicitudCambios";
import {
    DIRECTORIO_ID_MENU,
    MI_TABLERO_ID_MENU,
    MIS_DATOS_ID_MENU,
    MI_EQUIPO_ID_MENU,
    REPORTES,
    ADMIN_GESTION_FICHA_DATOS,
    ADMIN_SOLICITUD_DE_CAMBIOS,
    ADMIN_CESES,
    ADMIN_TABLAS_GENERALES,
    ADMIN_NUEVOS_INGRESOS,
    MENU_MIS_SOLICITUDES,
    ADMIN_GESTION_ROLES,
    ADMIN_GESTION_USUARIOS,
    ADMIN_GESTION_ENCUESTA_SALUD,
    ADMIN_GESTION_CIERRE_ANIO,
    ADMIN_GESTION_RESERVAS,
    ADMIN_GESTION_PROCESO_CIERRE, 
    ADMIN_GESTION_LIQUIDACIONES, 
    ADMIN_GESTION_VACUNACION, 
    ADMIN_GESTION_CAPACITACION,  
    ADMIN_GESTION_PRUEBAS_COVID, 
    ADMIN_ASIGNACION_FAMILIAR,
    ADMIN_GESTION_POLITICAS_REGLAMENTOS, 
    MENU_COVID,
    ADMIN_GESTION_DIRECTORIO, 
    ADMIN_GESTION_INVITACION_ONBOARDING, 
    ADMIN_GESTION_VACACIONES,
    ADMIN_GESTION_SCHEDULE_RUNNER,
    ADMIN_GESTION_PLANTILLAS,
    ADMIN_GESTION_NUEVOS_INGRESOS,
    ADMIN_GESTION_TRASLADOS,
    ADMIN_GESTION_REQUERIMIENTOS,
    ADMIN_GESTION_DESCUENTO_PENSION,
    ADMIN_SOLICITUD_TAILOY,
    ADMIN_GESTION_FICHA_SINTOMATOLOGICA,
    ADMIN_GESTION_SEGUIMIENTO_CASOS_COVID,
    ADMIN_GESTION_CUPONERA,
    ADMIN_GESTION_CHATBOT,
    ADMIN_GESTION_POPUP,
} from "../../constants/hardCodedConstants";
import NoTienePermisos from "./NoTienePermisos";
import {tieneAccesoAMenu} from "./permisosUtils";
import ProtectedRoute from './ProtectedRoute';
import AdminCeses from "../Ceses/AdminCeses";
import Principal from "../principal/Principal";
import AdminTablasGenerales from "../tablasGenerales/AdminTablasGenerales";
import AdminNuevosIngresos from "../nuevosIngresos/AdminNuevosIngresos";
import GestionDeRoles from "../gestionDeRoles/GestionDeRoles";
import PreguntasFrecuentes from "../preguntasFrecuentes/PreguntasFrecuentes";
import GestionDeUsuarios from "../gestionDeUsuarios/GestionDeUsuarios";
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { AuthContext } from '../../hooks/context/authContext';
import {getMenuPrincipalAPI} from "../../consumers/backendApisUrls";
import EncuestasDeSalud from "../encuestasDeSalud/EncuestasDeSalud";
import GestionCierreDeAnio from '../gestionCierreDeAnio/GestionCierreDeAnio';
import GestionReservas from '../gestionReservas/GestionReservas';
import ProcesosDeCierre from "../procesosDeCierre/ProcesosDeCierre";
import Liquidaciones from "../liquidaciones/Liquidaciones";
import ConfigurarProceso from '../liquidaciones/configurarProceso/ConfigurarProceso';
import DownloadReport from "../downloadReport/downloadReport";
import SeguimientoColaborador from "../SeguimientoColaborador/SeguimientoColaborador";
import GestionVacunacion from '../gestionVacunacion/GestionVacunacion';
import CheckRetornoClases from '../checkRetornoClases/CheckRetornoClases';
import GestionCapacitacion from '../gestionCapacitacion/GestionCapacitacion';
import GestionProgramacionPruebasCovid from '../gestionProgramacionPruebasCovid/gestionProgramacionPruebasCovid';
import GestorFichaSintomatologica from '../gestionFichaSintomatologica/GestorFichaSintomatologica';
import SeguimientoCasosCovid from '../seguimientoCasosCovid/SeguimientoCasosCovid';
import MisCapacitaciones from '../misCapacitaciones/MisCapacitaciones';
import Categorias from '../politicasYreglamentos/Categorias';
import Beneficios from '../politicasYreglamentos/Beneficios';
import PoliticasYReglamentos from '../politicasYreglamentos/PoliticasYReglamentos';
import GestionPoliticasYReglamentos from '../gestionPoliticasYReglamentos/GestionPoliticasYReglamentos';
import ConfirmacionDocumento from '../ConfirmacionDocumento/ConfirmacionDocumento';
import GestionDeDirectorio from '../gestionDeDirectorio/GestionDeDirectorio';
import GestionVacaciones from '../vacaciones/GestionDeVacaciones';
import ConfigurarVacaciones from "../vacaciones/configurarVacaciones/ConfigurarVacaciones";
import MisActividadesPendientes from "../nuevosIngresos/MisActividadesPendientes";
import HelpTooltip from "../../components/alerts/HelpTooltip";
import AsignarGruposIndex from '../procesosDeCierre/asignarGrupos/AsignarGruposIndex';
import AdminGrupos from '../procesosDeCierre/adminGrupos/AdminGrupos';
import AsignarPlantillas from '../procesosDeCierre/asignarPlantillas/AsignarPlantillas';
import RegistroInvitacionOnboarding from '../registroInvitacionOnboarding/RegistroInvitacionOnboarding';
import GestionSchedule from '../gestionSchedule/GestionSchedule';
import AdminSolicitudesAsignacionFamiliar from '../adminSolicitudesAsignacionFamiliar/AdminSolicitudesAsignacionFamiliar';
import GestionPlantillas from '../gestionPlantillas/GestionPlantillas';
import GestionEtiquetas from '../gestionEtiquetas/GestionEtiquetas';
import GestionComponentes from '../gestionComponentes/GestionComponentes';
import GestionNuevosIngresos from '../gestionNuevosIngresos/GestionNuevosIngresos';
import ConfigurarDocumentos from '../gestionNuevosIngresos/configurarDocumentos/ConfigurarDocumentos';
import ConfigurarListaDocumentos from '../gestionNuevosIngresos/configurarListaDocumentos/ConfigurarListaDocumentos';
import CrearFichaDatosNuevosIngresos from '../gestionNuevosIngresos/ficha/CrearFichaDatosNuevosIngresos';
import EditarFichaDatosNuevosIngresos from '../gestionNuevosIngresos/ficha/EditarFichaDatosNuevosIngresos';
import GestionSolicitudValeDescuento from '../gestionSolicitudes/gestionSolicitudValeDescuento/GestionSolicitudValeDescuento';
import GestionSolicitudDescuentoPension from '../gestionSolicitudes/gestionSolicitudDescuentoPension/GestionSolicitudDescuentoPension';
import MisVacaciones from '../vacaciones/MisVacaciones';
import GestionCuponeraTiempoLibre from '../cuponeraTiempoLibre/gestionCuponera/GestionCuponeraTiempoLibre';
import ListaCupones from '../cuponeraTiempoLibre/gestionCuponera/configuracionCupones/ListaCupones';
import CuponesAsignadosByCuponera from '../cuponeraTiempoLibre/gestionCuponera/configuracionCuponera/CuponesAsignadosByCuponera';
import CrearSolicitudCupon from '../cuponeraTiempoLibre/misSolicitudesCupon/CrearSolicitud/CrearSolicitudCupon';
import AdminSolicitudesCupon from '../cuponeraTiempoLibre/adminSolicitudesCupon/AdminSolicitudesCupon';
import ListaCuponeras from '../cuponeraTiempoLibre/misSolicitudesCupon/ListaCuponeras';
import MisSolicitudesCupones from '../cuponeraTiempoLibre/misSolicitudesCupon/solicitudesCupones/MisSolicitudesCupones';
import GestionRequerimientos from '../gestionRequerimientos/GestionRequerimientos';
import GestionTraslados from '../gestionTraslados/GestionTraslados';
import ConfigurarPerfilPuesto from '../gestionRequerimientos/configurarPerfilPuesto/ConfigurarPerfilPuesto';
import ConfigurarPresupuesto from '../gestionRequerimientos/configurarPresupuesto/ConfigurarPresupuesto';
import CrearNuevoIngreso from '../gestionRequerimientos/crearNuevoIngreso/CrearNuevoIngreso';
import GestionPostulantes from '../gestionTraslados/postulantes/GestionPostulantes';
import MisTraslados from '../traslados/MisTraslados';
import GestionChatbot from '../chatbot/gestionChatbot/GestionChatbot';
import ChatBot from '../chatbot/ChatBot';
import GestionPopup from '../gestionPopup/GestionPopup';

const AdminDash = () => {
    const {dispatch} = useContext(AuthContext);

    const [active, setActive] = useState(false);
    const [menusALosQueTieneAcceso, setMenusALosQueTieneAcceso] = useState([]);
    const [hideFichaSintomatologicaModal, setHideFichaSintomatologicaModal] = useState(true);
    const [hideContactanosModal, setHideContactanosModal] = useState(true);

    const [executeGet] = useManageGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    const init = async () => {
        await executeGet(getMenuPrincipalAPI(), successInitCallback)
    };

    const successInitCallback = (res) => {
        setMenusALosQueTieneAcceso(res.data);
        dispatch({
            type: 'MENUS_ACCESO',
            payload: {
                menusAcceso: res.data
            }
        });
    }

    const openSidebar = () => {
        setActive(!active);
    };

    const toggleFichaSintomatologicoModal = () => {
        setHideFichaSintomatologicaModal(!hideFichaSintomatologicaModal);
    };

    const toggleContactanosModal = () => {
        setHideContactanosModal(!hideContactanosModal);
    }
    
    return (
        <>
            <Header openSidebar={openSidebar} toggleFichaSintomatologicoModal={toggleFichaSintomatologicoModal} hideFichaSintomatologicaModal={hideFichaSintomatologicaModal} toggleContactanosModal={toggleContactanosModal} hideContactanosModal={hideContactanosModal}/>
            <div className="page-content">
                <Sidebar active={active} close={openSidebar} toggleFichaSintomatologicoModal={toggleFichaSintomatologicoModal}/>
                <div className="help-icon-tablero-container">
                    {/* <HelpTooltip /> */}
                    <ChatBot toggleContactanosModal={toggleContactanosModal}/>
                </div>

                <Switch>
                    <Route component={ConfirmacionDocumento} exact path='/vaccineconfirmation/:id'/>
                    <ProtectedRoute id={DIRECTORIO_ID_MENU} component={Directorio} path='/directorio'/>
                    <ProtectedRoute id={MIS_DATOS_ID_MENU} component={FichaDatos} path='/fichadedatos/:from?/:id?'/>
                    <ProtectedRoute id={MI_EQUIPO_ID_MENU} component={MiEquipo} path='/miequipo'/>
                    <ProtectedRoute id={REPORTES} component={Reportes} path='/reportes'/>

                    {/* ADMINISTRADOR */}

                    <ProtectedRoute id={ADMIN_GESTION_FICHA_DATOS} component={GestionFichaDatos} path='/gestionFichaDatos'/>
                    <ProtectedRoute id={ADMIN_SOLICITUD_DE_CAMBIOS} component={AdminSolicitudCambios} path='/adminSolicitudCambios'/>
                    <ProtectedRoute id={ADMIN_CESES} component={AdminCeses} path='/adminRenuncias'/>
                    <ProtectedRoute id={ADMIN_TABLAS_GENERALES} component={AdminTablasGenerales} path='/adminTablasGenerales'/>
                    <ProtectedRoute id={ADMIN_NUEVOS_INGRESOS} component={AdminNuevosIngresos} path='/nuevosIngresos'/>
                    <ProtectedRoute id={ADMIN_GESTION_ROLES} component={GestionDeRoles} path='/adminGestionRoles'/>
                    <ProtectedRoute id={ADMIN_GESTION_USUARIOS} component={GestionDeUsuarios} path='/adminGestionUsuarios'/>
                    <ProtectedRoute id={ADMIN_GESTION_CAPACITACION} component={GestionCapacitacion} path='/adminGestionCapacitacion'/>
                    <ProtectedRoute id={ADMIN_GESTION_PRUEBAS_COVID} component={GestionProgramacionPruebasCovid} path='/adminGestionProgramacionPruebasCovid'/>

                    <Route component={PreguntasFrecuentes} path='/tutoriales'/>

                    <ProtectedRoute id={ADMIN_GESTION_ENCUESTA_SALUD} component={EncuestasDeSalud} path='/adminGestionEncuestaSalud'/>
                    <ProtectedRoute id={ADMIN_GESTION_CIERRE_ANIO} component={GestionCierreDeAnio} path='/adminGestionCierreAnio'/>
                    
                    <ProtectedRoute id={ADMIN_GESTION_POLITICAS_REGLAMENTOS} component={GestionPoliticasYReglamentos} path='/gestionPoliticasYReservas'/>
                    <ProtectedRoute id={ADMIN_GESTION_VACUNACION} component={GestionVacunacion} path='/gestionVacunacion'/>

                    <ProtectedRoute id={ADMIN_GESTION_PROCESO_CIERRE} component={ProcesosDeCierre} exact path='/procesosDeCierre'/>
                    <ProtectedRoute id={ADMIN_GESTION_PROCESO_CIERRE} component={AsignarGruposIndex} exact path='/procesosDeCierre/AsignarGruposIndex/:id'/>
                    <ProtectedRoute id={ADMIN_GESTION_PROCESO_CIERRE} component={AdminGrupos} exact path='/procesosDeCierre/adminGrupos'/>
                    <ProtectedRoute id={ADMIN_GESTION_PROCESO_CIERRE} component={AsignarPlantillas} exact path='/procesosDeCierre/asignarPlantillas/:id'/>
                    <ProtectedRoute id={ADMIN_GESTION_LIQUIDACIONES} component={Liquidaciones} exact path='/liquidaciones'/>
                    <ProtectedRoute id={ADMIN_GESTION_LIQUIDACIONES} component={ConfigurarProceso} exact path='/liquidaciones/configurarProceso'/>
                    <ProtectedRoute id={ADMIN_GESTION_DIRECTORIO} component={GestionDeDirectorio} path='/adminGestionDirectorio'/>
                    <ProtectedRoute id={ADMIN_GESTION_VACACIONES} component={GestionVacaciones} exact path='/adminGestionVacaciones'/>
                    <ProtectedRoute id={ADMIN_GESTION_VACACIONES} component={ConfigurarVacaciones} exact path='/adminGestionVacaciones/configurarVacaciones'/>
                    <ProtectedRoute id={ADMIN_GESTION_INVITACION_ONBOARDING} component={RegistroInvitacionOnboarding} exact path='/registroInvitacionOnboarding'/>
                    <ProtectedRoute id={ADMIN_GESTION_SCHEDULE_RUNNER} component={GestionSchedule} path='/adminSchedule'/>
                    <ProtectedRoute id={ADMIN_ASIGNACION_FAMILIAR} component={AdminSolicitudesAsignacionFamiliar} path='/adminSolicitudesAsignacionFamiliar'/>
                    <ProtectedRoute id={ADMIN_GESTION_FICHA_SINTOMATOLOGICA} component={GestorFichaSintomatologica} path='/gestionFichaSintomatologica'/>
                    <ProtectedRoute id={ADMIN_GESTION_SEGUIMIENTO_CASOS_COVID} component={SeguimientoCasosCovid} path='/seguimientoCasosCovid'/>
                    <ProtectedRoute id={ADMIN_GESTION_PLANTILLAS} component={GestionPlantillas} path='/adminPlantillas'/>
                    <ProtectedRoute id={ADMIN_GESTION_PLANTILLAS} component={GestionEtiquetas} path='/adminEtiquetas'/>
                    <ProtectedRoute id={ADMIN_GESTION_PLANTILLAS} component={GestionComponentes} path='/adminComponentes'/>
                    <ProtectedRoute id={ADMIN_GESTION_NUEVOS_INGRESOS} component={GestionNuevosIngresos} exact path='/adminNuevosIngresos'/>
                    <ProtectedRoute id={ADMIN_GESTION_NUEVOS_INGRESOS} component={ConfigurarDocumentos} exact path='/adminNuevosIngresos/configurarDocumentos'/>
                    <ProtectedRoute id={ADMIN_GESTION_NUEVOS_INGRESOS} component={ConfigurarListaDocumentos} exact path='/adminNuevosIngresos/configurarListaDocumentos'/>
                    <ProtectedRoute id={ADMIN_GESTION_NUEVOS_INGRESOS} component={CrearFichaDatosNuevosIngresos} exact path='/adminNuevosIngresos/crearNuevoIngreso'/>
                    <ProtectedRoute id={ADMIN_GESTION_NUEVOS_INGRESOS} component={EditarFichaDatosNuevosIngresos} exact path='/adminNuevosIngresos/EditarNuevoIngreso/:id?'/>
                    <ProtectedRoute id={ADMIN_GESTION_REQUERIMIENTOS} component={GestionRequerimientos} exact path='/adminGestionRequerimientos'/>
                    <ProtectedRoute id={ADMIN_GESTION_REQUERIMIENTOS} component={ConfigurarPerfilPuesto} exact path='/adminGestionRequerimientos/configurarPerfilPuesto'/>
                    <ProtectedRoute id={ADMIN_GESTION_REQUERIMIENTOS} component={ConfigurarPresupuesto} exact path='/adminGestionRequerimientos/configurarPresupuesto'/>
                    <ProtectedRoute id={ADMIN_GESTION_REQUERIMIENTOS} component={CrearNuevoIngreso} exact path='/adminGestionRequerimientos/crearNuevoIngreso/:id'/>
                    {/* <ProtectedRoute id={ADMIN_GESTION_TRASLADOS} component={GestionTraslados} exact path='/adminTraslados'/>
                    <ProtectedRoute id={ADMIN_GESTION_TRASLADOS} component={GestionPostulantes} exact path='/adminTraslados/PostulantesCampaña/:id?'/> */}
                    <ProtectedRoute id={ADMIN_GESTION_DESCUENTO_PENSION} component={GestionSolicitudDescuentoPension} exact path='/gestionSolicitudDescuentoPension'/>
                    <ProtectedRoute id={ADMIN_SOLICITUD_TAILOY} component={GestionSolicitudValeDescuento} exact path='/adminSolicitudValeDescuento'/>
                    <ProtectedRoute id={ADMIN_GESTION_CUPONERA} component={AdminSolicitudesCupon} exact path='/adminCuponeraTiempoLibre'/>
                    <ProtectedRoute id={ADMIN_GESTION_CUPONERA} component={GestionCuponeraTiempoLibre} exact path='/adminCuponeraTiempoLibre/listaCuponera'/>
                    <ProtectedRoute id={ADMIN_GESTION_CUPONERA} component={ListaCupones} exact path='/adminCuponeraTiempoLibre/listaCupones'/>
                    <ProtectedRoute id={ADMIN_GESTION_CUPONERA} component={CuponesAsignadosByCuponera} exact path='/adminCuponeraTiempoLibre/verCuponesAsignadosByCuponera'/>
                    <ProtectedRoute id={ADMIN_GESTION_CHATBOT} component={GestionChatbot} exact path='/adminChatbotFaq'/> 
                    <ProtectedRoute id={ADMIN_GESTION_POPUP} component={GestionPopup} exact path='/adminPopup'/> 
                    
                    <Route component={DownloadReport} path='/downloadFile/:path?/:fileName?'/>

                    {/* MIS SOLICITUDES */}

                    {(tieneAccesoAMenu(menusALosQueTieneAcceso, ADMIN_GESTION_RESERVAS) || tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.misReservas)) && <Route component={GestionReservas} path='/gestionReservas'/>}
                    {!tieneAccesoAMenu(menusALosQueTieneAcceso, ADMIN_GESTION_RESERVAS) && !tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.misReservas) && <Route component={NoTienePermisos} path='/adminGestionEncuestaSalud'/>}

                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.seguimientoColaboradores} component={SeguimientoColaborador} path='/seguimientoColaboradores'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.politicasYReglamentos} component={Categorias} exact path='/politicasYreglamentos'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.politicasYReglamentos} component={PoliticasYReglamentos} exact path='/politicasYreglamentos/categoria/:id'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.politicasYReglamentos} component={Beneficios} exact path='/politicasYreglamentos/beneficios'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.misActividades} component={MisActividadesPendientes} path='/misActividadesPendientes'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.renuncias} component={AdminCeses} path='/MisRenuncias'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.MisVacaciones} component={MisVacaciones} path='/misVacaciones'/>
                    {/* <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.MisTraslados} component={MisTraslados} path='/misTraslados'/> */}
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.cierreDeAnio} component={GestionCierreDeAnio} path='/MiCierreAnio'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.misCapacitaciones} component={MisCapacitaciones} path='/misCapacitaciones'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.MisCuponesTiempoLibre} component={ListaCuponeras} exact path='/misCuponesTiempoLibre'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.MisCuponesTiempoLibre} component={CrearSolicitudCupon} exact path='/misCuponesTiempoLibre/crearSolicitud'/>
                    <ProtectedRoute id={MENU_MIS_SOLICITUDES.childMenuIds.MisCuponesTiempoLibre} component={MisSolicitudesCupones} exact path='/misCuponesTiempoLibre/misSolicitudes'/>

                    {/* COVID */}
                    <ProtectedRoute id={MENU_COVID.childMenuIds.retornoClases} component={CheckRetornoClases} path='/checkRetornoClases'/>
                    <ProtectedRoute id={MI_TABLERO_ID_MENU} component={Principal} exact path='/'/>

                </Switch>
            </div>
        </>
    );
};

export default AdminDash;

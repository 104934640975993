import React, {useContext, useState} from 'react';
import store from 'store';
import 'firebase/auth';
import {Redirect} from 'react-router-dom';

import imgss from '../image/innova_students.png';
import innova from '../image/innova.png';

import {AuthContext} from '../hooks/context/authContext';
import {validateLogin} from '../consumers/backendConsumer';
import {auth, googleProvider} from '../services/firebase';
import {EMAIL, PHOTO_URL} from '../constants';
import {Wrapper, Intranet} from '../components/atoms/WrapperLogin';
import { isNullOrUndefinded } from '../helper/utils';

const Login = ({url}) => {
    const {dispatch, state} = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const validateLog = async (email, photo) => {
        try {
            const response = await validateLogin(email, photo);
            if (response.status === 200) {
                return await dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: {
                            email: response.data.data.Email,
                            id_usuario: response.data.data.IdUsuario,
                            id_empleado: response.data.data.IdEmpleado,
                            name: response.data.data.Nombre,
                            fullName: response.data.data.NombreCompleto,
                            photo: response.data.data.ProfileImageUrl,
                        },
                        token: response.data.data.AuthToken,
                        isLoggedIn: true,
                        role: response.data.data.Roles,
                        roleIds: response.data.data.IdRoles,
                        terms: response.data.data.showTermsMessage,
                        cookies: response.data.data.showCookiesMessage,
                        cookiesMessage: response.data.data.CookiesMessage,
                        isInterno: response.data.data.EsInterno
                    },
                });
            } else {
                setErrorMessage(response.response.data.Message);
                setError(true);
            }
        } catch (e) {
            setError(true);
            console.error('error', e);
        }
    };

    const signInWithGoogle = () => {
        auth
            .signInWithPopup(googleProvider)
            .then((res) => {
                console.debug("res: ", res);
                console.debug("user: ", res?.user);
                if (res.user.email !== null) {
                    validateLog(res.user.email, res.user.photoURL);
                    store.set(EMAIL, res.user.email);
                    store.set(PHOTO_URL, res.user.photoURL);
                }
            })
            .catch((e) => {
                console.error(e.message);
            });
    };

    if (state.isLoggedIn) {
        // return <Redirect to='/'/>;
        return <Redirect to={{pathname: isNullOrUndefinded(url) ? '/' : url.from, state:{search: url?.search}}}/>;
    }
    return (
        <Wrapper>
            <Intranet image={imgss}/>

            <div className='login'>
                <div>
                    <div className='container-button'>
                        <img src={innova} alt='' style={{width: '50%'}}/>
                    </div>
                    <div className='container-button-mob'>
                        <img src='https://intranetinnova.github.io/IntranetFileRepository/Logos/innova_mob.png' alt='' style={{width: '50%'}}/>
                    </div>
                    <div className='container-button1'>
                        <button onClick={signInWithGoogle} className="font-gothan-light button-resett">COMENCEMOS</button>
                        <div className="mensajes-error-inicio-sesion">
                            {state.notificationMessage && <p>{state.notificationMessage}</p>}
                            {error && (<p>{errorMessage}</p>)}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Login;
